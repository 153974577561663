.full-order-container {
  .div-order-table {
    border-collapse: collapse;
    overflow-y: scroll;
    min-height: 30 vh;
    max-height: 50vh;
  }
  .table-header {
    font-weight: bold;
    color: black;
  }

  .ui.table {
    border: 0px !important;
  }

  .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .ui.table thead th {
    cursor: default;
    border-bottom: 0px !important;
  }
  .ui.table tr td {
    border-top: 0px !important;
  }

  .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 60px 10px 0 50px;
    width: 95%;
    border-radius: 15px;
  }

  .ui.dropdown {
    border-radius: 30px;
  }

  .div-center {
    font-size: 18px;
    width: max-content;
    height: 100px;
    margin-left: 230%;
    margin-top: 60px;
  }
  .div-center-noData {
    font-size: 18px;
    width: max-content;
    height: 100px;
    margin-left: 290%;
    margin-top: 60px;
  }
}

@media only screen and (max-width: 567px) {
  .full-order-container {
    width: 50%;

    .div-order-table {
      min-width: 50%;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .full-order-container {
    width: 97%;
  }
}
