.main {
  flex: 1;
  height: auto;
  width: 100;
  margin-left: 220px;
}
.shop-header {
  display: flex;
  padding: 3vh 0;
  width: 100%;
  height: 40px;
  margin: 0 80px 0 65px;
  font-size: 30px;
  font-weight: bold;
  color: #474747;
}
.page-header {
  display: flex;
  font-family: "Montserrat", sans-serif !important;
  font-size: 23px;
  padding: 7px 0;
  width: 100%;
  font-weight: bold;
  color: #474747;

  //Dropdown override for header
  .ui.selection.dropdown {
    cursor: pointer;
    min-height: 1em;
    background: transparent !important;
    padding: 0em 2.1em 0.78571429em 1em !important;
    border: none !important;
  }
  .ui.selection.dropdown > .delete.icon,
  .ui.selection.dropdown > .dropdown.icon,
  .ui.selection.dropdown > .search.icon {
    top: 0 !important;
  }
}

.svg-inline--fa {
  display: inline-block;
  font-size: 30px;
  height: 2em;
  vertical-align: -0.125em;
  position: relative;
}
.svg-inline--fa.fa-w-11 {
  color: white;
  margin-left: 220px;
  padding-top: 20px;
}
.svg-inline--fa.fa-w-14 {
  padding-top: 20px;
}

@media only screen and (max-width: 567px) {
  .main {
    flex: 1;
    height: auto;
    width: 100;
    margin-left: 20px;
  }
}
