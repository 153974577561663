.full-settings-container {
  width: 95%;

  .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 30px 10px 0 20px;
    width: 97%;
    border-radius: 15px;
    height: 80vh;
  }

  h3 {
    color: black;
    margin-left: 20px;
  }
  .ui.header {
    margin: 0 80px 0 30px;
    font-size: 1.9em;
  }
  .icon-style {
    margin-left: 30px;
  }
  .lable-span {
    margin-left: 10px;
    color: black;
  }
}

@media only screen and (max-width: 567px) {
  .full-settings-container {
    width: 50%;

    .div-order-table {
      min-width: 50%;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .full-settings-container {
    width: 100%;
  }
}
