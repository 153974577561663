.button-primary {
  background-color: $primary-button-color !important;
}

.no-session-button {
  padding: 0.8vw 2vw !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
  border-radius: 50px !important;
}
@media screen and (max-width: 1366px) {
  .no-session-button {
    padding: 1vw 3vw !important;
  }
}

@media screen and (max-width: 958px) {
  .no-session-button {
    padding: 1.5vw 3vw !important;
  }
}

@media screen and (max-width: 758px) {
  .no-session-button {
    padding: 2vw 3vw !important;
  }
}

@media screen and (max-width: 500px) {
  .no-session-button {
    padding: 3vw 5vw !important;
  }
}
