.blue-bar {
  display: flex;
  flex-direction: column;
  width: 250px;
  background-color: $override-link-color;
  min-height: 100vh;
  height: 100%;
  border-radius: 10px;
  position: fixed;
}
.blue-bar-top {
  flex: 1;
}
.blue-bar-bottom {
  display: block;
  flex-direction: column-reverse;
}
.navbar-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    padding: 20px;
  }
}

.navbar-container {
  display: flex;
  flex-direction: column;
  background-color: $override-link-color;
  padding-top: 15px;
  min-height: 100vh;
  align-items: center;
  box-shadow: -1px 2px 1px -1px black;
  z-index: 2;
  min-width: 260px;
}

.active-nav-link {
  // border-radius: 10px;
  // padding: 5px 20px 5px 15px;
  background-color: $override-link-hover-color;
  font-weight: bold;
  .fontawesome-icons {
    color: $active-icon-color;
  }
}

.menu-div {
  margin-top: 20px;
}
.menu-items {
  list-style-type: none;
  padding: 0;
  width: 250px;
  font-size: 15px;
  color: #f7fbff;
  a {
    padding: 15px 0px 15px 15px;
    text-decoration: none;
    color: white;
    display: flex;
    align-content: center;
  }
  li {
    padding: 5px;
    list-style-type: none;
  }
}

.logo-check {
  position: relative;
  top: 20px;
  left: 40px;
  width: 150px;
}
.sign-out {
  display: none;
  position: absolute;
  left: 32px;
  bottom: -5px;
  width: 240px;
  height: 60px;
  text-align: center;
  background-size: contain;
}
.sign-out a {
  display: block;
  padding-right: 40px;
  margin-top: 20px;
}
.sign-out:hover {
  display: block;
}

.my-account {
  position: relative;
  bottom: 20px;
  left: 15px;
}
.my-account-name {
  width: 180px;
  margin: 24px 15px 0 15px;
  font-size: 15px;
  color: #f7fbff;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}
.my-account-link {
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #002d5e;
  background-color: #fff;
  text-decoration: none;
  padding: 10px;
  border-radius: 20px;
  transform: translateX(10%);
}
.my-account-link:hover {
  color: #fff;
  background-color: #dde2e6;
}
.my-account-link:hover + .sign-out {
  display: block;
}

.div-shop {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;

  .my-shop {
    width: 40px;
    height: 40px;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #002d5e;
    background-color: #fff;
    margin: 0 15px;
    padding: 10px 13px;
    border-radius: 20px;
    transform: translateX(-12%);
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: $override-link-color;
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close {
  width: 68px;
}
.sidebar .logo-details {
  height: 60px;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.sidebar .logo-details i {
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
  margin-top: 0px;
}

.sidebar.close .check-logo {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}

.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  padding: 20px 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover {
  background: $override-link-hover-color;
  // border-radius: 10px;
}
.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .iocn-link {
  display: block;
}
.logout {
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fontawesome-icons {
  height: 44px;
  min-width: 55px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  writing-mode: vertical-rl;
}
.fa-calendar:before {
  font-size: 18px;
}

.fa-comments:before {
  font-size: 18px;
}

.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $override-link-color;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: white;
}
.sidebar.close .profile-details {
  width: 78px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details .profile-initials {
  height: 40px;
  width: 40px;
  font-size: 15px;
  font-weight: bold;
  color: #002d5e;
  background-color: #fff;
  text-decoration: none;
  padding: 10px;
  text-align: center;
  border-radius: 50px;
  transition: all 0.6s ease;
}
.sidebar.close .profile-details .profile-initials {
  height: 40px;
  width: 40px;
  font-size: 15px;
  font-weight: bold;
  color: #002d5e;
  background-color: #fff;
  text-decoration: none;
  padding: 10px;
  text-align: center;
  border-radius: 50px;
  transition: all 0.5s ease;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details {
  display: none;
}
.sidebar .profile-details {
  font-size: 12px;
}
.home-section {
  position: relative;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  padding: 12px;
}
.sidebar.close ~ .home-section {
  left: 68px;
  width: calc(100% - 68px);
}
.home-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.home-section .home-content .bx-menu,
.home-section .home-content .main-text {
  color: #474747;
  font-size: 35px;
}
.home-section .home-content .bx-menu {
  cursor: pointer;
  margin-right: 10px;
}
.home-section .home-content .main-text {
  font-size: 26px;
  font-weight: 600;
}
.home-section select#lang {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 130px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  cursor: pointer;
  outline: none !important;
  border-bottom: 2px solid #006f9f;
  z-index: 2;
}

@media screen and (max-width: 400px) {
  .sidebar {
    width: 240px;
  }
  .sidebar.close {
    width: 78px;
  }
  .sidebar .profile-details {
    width: 230px;
  }
  .sidebar.close .profile-details {
    background: none;
  }
  .sidebar.close .profile-details {
    width: 78px;
  }
  .home-section {
    left: 240px;
    width: calc(100% - 240px);
  }
  .sidebar.close ~ .home-section {
    left: 78px;
    width: calc(100% - 78px);
  }
}
