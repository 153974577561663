.full-feedback-container {
  margin-bottom: 30px;
  .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 60px 10px 0 50px;
    width: 95%;
    border-radius: 15px;
    .content {
      margin: 0 0 40px 0;
    }
  }

  .table-header {
    font-weight: bold;
    color: black;
  }

  .dash-table {
    border-collapse: collapse;
    overflow-y: scroll;
    min-height: 40vh;
    max-height: 45vh;
    width: 95%;
    margin-left: 50px;
    margin-top: 50px;
  }

  .ui.table {
    border: 0px !important;
  }

  .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .ui.table thead th {
    cursor: default;
    border-bottom: 0px !important;
  }
  .ui.table tr td {
    border-top: 0px !important;
  }
  .rating-summary {
    margin-left: 50px;

    p {
      color: black;
      font-size: medium;
      font-weight: bold;
    }
  }
  .ui.progress {
    position: relative;
    display: block;
    max-width: 100%;
    border: none;
    margin: 0 0 1em;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0.28571429rem;
    //background-color: #ffc373;

    .bar {
      background-color: #ffc373;
    }
  }

  .item-count {
    color: $primary-color;
    font-size: 40px;
    text-align: center;
  }

  .progress-chart {
    width: "80%";
    float: "left";
  }

  .div-filters,
  .div-filters-active {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 1rem;
    margin-right: 30px;
    margin-bottom: 10px;
    min-width: 60px;
    border: solid grey 1px;
    cursor: pointer;
    width: 15%;
    text-align: center;
  }

  .view-all-button {
    color: $dark-gray;
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer;
  }

  .div-center {
    font-size: 18px;
    width: 200px;
    position: absolute;
    left: 0;
    top: 70%;
    bottom: 0;
    right: 0;
    margin: auto;
  }

  .div-align-block {
    display: block;
    margin-top: 15px;
  }

  @media only screen and (max-width: 1366px) {
    .full-feedback-container {
      width: 80%;
    }
  }
}

.margin-left {
  margin-left: 20px;
}

.margin-top {
  margin-top: 15px;
}

.div-align-flex {
  display: flex;
  margin-top: 15px;
}

.align-center {
  text-align: center;
}
