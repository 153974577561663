.privacy-policy-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  order: 1;
  flex: 1 0 100%;
  footer {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    background: #f8f8f8;
    width: auto;
    max-width: inherit;
    overflow-x: hidden;

    #lang {
      width: 130px;
      padding-left: 10px;
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;
      cursor: pointer;
      outline: none !important;
      border-bottom: 2px solid #006f9f;
      height: 48px;
    }
  }
  .info .legal {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    font-weight: 500;
  }

  .info .legal > a {
    margin: 0 10px;
    text-decoration: none;
    font-weight: 500;
  }

  a[target="_blank"]::after {
    content: " ";
  }

  .info {
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f8f8f8;
    color: #777777;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 600px) {
    .info {
      justify-content: center;
      flex-direction: column;
    }

    .info > .copyright {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 380px) {
    .info .legal {
      justify-content: center;
    }

    .info .legal a {
      margin: 0;
      text-align: center;
    }
  }
}
