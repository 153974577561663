.full-dashboard-container {
  .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 60px 10px 0 50px;
    width: 95%;
    border-radius: 15px;
  }
  .ui.active.button {
    z-index: 10;
  }

  .table-header {
    font-weight: bold;
    color: black;
    font-family: "Montserrat", sans-serif !important;
  }

  .dash-table {
    border-collapse: collapse;
    overflow-y: scroll;
    min-height: 25vh;
    max-height: 28vh;
  }

  .item-count {
    color: $primary-color;
    font-size: 40px;
  }
  .ui.table {
    border: 0px !important;
  }

  .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .ui.table thead th {
    cursor: default;
    border-bottom: 0px !important;
  }
  .ui.table tr td {
    border-top: 0px !important;
  }
  .adjust-button {
    padding: 5px;
    font-size: 15px;
    float: right;
    text-align: center;
    border-radius: 20px;
    background-color: #a3a2b3;
    color: whitesmoke;
  }

  .pricing-summary {
    position: relative;
    text-align: center;
    width: 100%;
    padding: 50px 20px;
    margin-top: 10px;
  }

  .div-center {
    font-size: 20px;
    font-weight: 700;
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .no-content {
    position: absolute;
    font-size: 20px;
    font-weight: 700;
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .view-all-button {
    color: $dark-gray;
    position: absolute;
    right: 20px;
    text-decoration: underline;
  }
  .expand-button {
    width: 100px;
    height: 30px;
    padding: 5px;
    font-size: 15px;
    float: right;
    text-align: center;
    border-radius: 20px;
    background-color: #a3a2b3;
    color: whitesmoke;
  }
  .pricing-error-msg {
    color: red;
    position: relative;
    font-size: 11px;
  }

  @media only screen and (max-width: 1366px) {
    .full-dashboard-container {
      width: 80%;
    }
  }

  .ui.icon.input > input {
    color: #a8b1ba;
    background: #f2f4f4;
    .ui.icon.input > i.icon:not(.link) {
      color: #a3a2b3;
    }
  }
  .ui.icon.input > input:focus {
    background: white;
    .ui.icon.input > i.icon:not(.link) {
      color: #b0b0b5;
    }
  }
  .ui.icon.input > i.icon:after,
  .ui.icon.input > i.icon:before {
    color: #b0b0b5;
  }
}
