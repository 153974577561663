$primary-color: #0b53fa;
$primary-color-gradient-start: #002d5e;
$primary-color-gradient-end: #1b5492;
$primary-text-color: white;
$no-session-block-color: #0b53fa;
$primary-button-color: #ea0661;
$primary-button-hover-color: #c40451;
$secondary-button-color: #f7f7f7;
$secondary-button-hover-color: #fafafa;
$tertiary-button-color: #999999;
$tertiary-button-hover-color: #888888;
$primary-button-text-color: #f7f7f7;
$secondary-button-text-color: #f8991d;
$tertiary-button-text-color: #f7f7f7;
$black: #000000;
$white: #ffffff;
$carousel-title: $white;
$primary-link-color: #4183c4;
$override-link-color: #1354fa;
$override-link-hover-color: #0900ba;
$active-icon-color: white;
$button-hover-color: #999999;

/* To add more CarAdvise styling, see https://caradvise.atlassian.net/wiki/spaces/PR/pages/21332001/Digital+Components?preview=/21332001/21200917/CarAdvise_DigitalComponents.pdf */

/* Fixed Color Scheme */
$very-light-gray: #f7f7f7;
$light-gray: #efefef;
$gray: #dddddd;
$dark-gray: #474747;
$darkest-gray: #414447;

$orange: #f8991d;
$blue: #0390ff;
$navy-blue: #002d5e;
$caradvise-electric-blue: #0390ff;

$light-green: #8dd68a;
$red: #ff0000;
$font-family: "Montserrat", sans-serif !important;
$reschedule-color: #ffc373;
/* Color classes */
.ca-very-light-gray {
  color: $very-light-gray;
}

.background-very-light-gray {
  background-color: $very-light-gray;
}

.ca-light-gray {
  color: $light-gray;
}

.ca-gray {
  color: $gray;
}

.ca-dark-gray {
  color: $dark-gray;
}

.ca-darkest-gray {
  color: $darkest-gray;
}

.ca-orange {
  color: $orange;
}

.ca-blue {
  color: $blue;
}

.ca-navy-blue {
  color: $navy-blue;
}

.ca-caradvise-electric-blue {
  color: $caradvise-electric-blue;
}

.ca-light-green {
  color: $light-green;
}

.ca-red {
  color: $red;
}

$progress-bar-active: #1bff1b;
$progress-bar-inactive: $gray;

$apex-chart-primary-color: $override-link-color;
$apex-chart-secondary-color: #ffc373;
