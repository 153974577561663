body {
  margin: 0;
  padding: 0;
  color: #666666;
}

.app {
  display: flex;
  background-color: #EFEFEF;
  min-height: 100vh;
}

.flex-container {
  display: flex;
  flex: 1;
  align-items: center;
}
