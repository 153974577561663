.full-container {
  margin-top: 30px;

  .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 60px 10px 0 50px;
    width: 95%;
    border-radius: 15px;
  }

  .expand-icon {
    margin: 5px;
    color: #a3a2b3;
  }

  .table-header {
    font-weight: bold;
    color: black;
  }

  .expand-button {
    width: 100px;
    height: 30px;
    padding: 5px;
    font-size: 15px;
    float: right;
    text-align: center;
    border-radius: 20px;
    background-color: #a3a2b3;
    color: whitesmoke;
  }
  .table {
    border-collapse: collapse;
    overflow-y: scroll;
    min-width: 45vh;
  }

  .ui.table {
    border: 0px !important;
  }

  .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .ui.table thead th {
    cursor: default;
    border-bottom: 0px !important;
  }
  .ui.table tr td {
    border-top: 0px !important;
  }

  .div-center {
    font-size: 18px;
    width: max-content;
    height: 50px;
    margin-left: 325%;
    margin-top: 60px;
  }
  .pricing-error-msg {
    color: red;
    position: relative;
    font-size: 11px;
  }
}

.full-pricing-container {
  margin: 0 0 0 3%;
  width: 95%;

  .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    border-radius: 10px;
  }

  .ui.fluid.card {
    margin: 20px 10px 0 10px;
    width: 98%;
  }

  table.borderless {
    border: 1px !important;
    th {
      background-color: white !important;
    }
  }

  .pricing-header {
    overflow: hidden;
  }
  .pricing-table {
    overflow-y: auto;
    min-height: 75vh;
    max-height: 75vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 20px 2px 2px 2px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .ui.card > .content > .header,
  .ui.cards > .card > .content > .header {
    margin-left: 10px;
    padding: 0;
  }

  .ui.table thead th {
    font-size: 16px;
    padding: 0.928571em 1.785714em;
  }
  .ui.dropdown {
    width: 50%;
  }

  .ui.dropdown .menu {
    min-width: 100%;
  }
  .ui.dropdown .menu > .item > .image,
  .ui.dropdown .menu > .item > img,
  .ui.dropdown > .text > .image,
  .ui.dropdown > .text > img {
    vertical-align: middle;
  }

  .labor-rate {
    display: inline-flex;
    color: #343434;
    display: flex;
    float: right;
    font-size: 16px;
    font-weight: 500;
    span {
      width: 100%;
    }
    p {
      margin-left: 20px;
      width: 400px;
    }
  }

  .caradvise-price {
    background-color: #f6f6f6;
    border-radius: 4px;
    margin: 0 30%;
    padding: 0.4rem 1.4rem;
    font-size: 18px;
    font-weight: 600;
    color: $black !important;
  }

  .ui.grid {
    margin: 0;
  }

  .caradvise-labor-price {
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 5px 6%;
    font-size: 16px;
    font-weight: 600;
    margin: 0 2%;
    border-radius: 5px;
    text-align: center;
  }

  .caradvise-labor-price-input {
    padding: 5px 2%;
    text-align: center;
    flex: none;
    max-width: 26%;
    margin: 0;
    border-radius: 5px;
    color: #1354fa;
  }

  .retail-savings {
    background-color: #f6f6f6;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    padding: 1% 20%;
  }

  .retail-savings-display-none {
    margin: 0 20%;
  }

  .ui.table td.one.wide,
  .ui.table th.one.wide {
    width: 13.25%;
  }
  .ui.table td {
    padding: 0.2em 0.78571429em;
  }

  .labor-rate-price-div {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    width: 7%;
    margin-left: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .pricing-row-input {
    //padding: 3% 0;
    text-align: center;
    max-width: 40%;
    margin: 0;
    flex: none;
  }

  .ui.input > input {
    padding: 5px 0;
    text-align: center;
    background-color: #f6f6f6;
    line-height: 0px;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: none;
    font-size: 16px;
    font-weight: 600;
    flex: none;
    position: inherit;
  }

  .ui.input.error > input {
    border: 2px solid #ff6b6b;
    background-color: #f6f6f6;
  }

  .input-pricing {
    font-family: "Montserrat", sans-serif !important;
    background-color: #f6f6f6;
    margin: 0 30%;
    padding: 0.5rem 0.9rem;
    font-weight: 600;
    display: flex;
    width: 135px;
    color: #2f3036;
    background-color: #edf0f5;
    border-radius: 4px;

    .prefix {
      font-size: 9px;
      color: #2f3036;
      font-weight: 600;
    }
    .suffix {
      font-size: 9px;
      color: #67768e;
      cursor: pointer;
    }
    .svg-inline--fa {
      vertical-align: -5px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input {
      all: initial;
      width: 90px !important;
      font-size: 18px;
      font-weight: 600;
      font-family: "Montserrat", sans-serif !important;
    }
  }

  .input-pricing-error {
    font-family: "Montserrat", sans-serif !important;
    border: 2px solid #ff6b6b;
    margin: 0 30%;
    padding: 0.5rem 0.9rem;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    width: 135px;
    color: #2f3036;
    background-color: #edf0f5;
    border-radius: 4px;
    .prefix {
      font-size: 9px;
      color: #2f3036;
      font-weight: 600;
    }
    .suffix {
      font-size: 9px;
      color: #67768e;
      cursor: pointer;
    }
    .svg-inline--fa {
      vertical-align: -5px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input {
      all: initial;
      width: 90px !important;
      font-weight: 600;
      font-family: "Montserrat", sans-serif !important;
    }
  }

  .input-rate {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    width: 200px;
    color: #2f3036;
    background-color: #edf0f5;
    border-radius: 4px;
    padding: 1rem 1rem;

    .prefix {
      font-size: 10px;
      color: #2f3036;
    }
    .suffix {
      font-size: 10px;
      color: #67768e;
      cursor: pointer;
    }
    .svg-inline--fa {
      vertical-align: -5px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .input-rate-error {
    border: 2px solid #ff6b6b;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    width: 200px;
    color: #2f3036;
    background-color: #edf0f5;
    border-radius: 4px;
    padding: 1rem 1rem;
    .prefix {
      font-size: 10px;
      color: #2f3036;
    }
    .suffix {
      font-size: 10px;
      color: #67768e;
      cursor: pointer;
    }
    .svg-inline--fa {
      vertical-align: -5px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  input {
    all: unset;
    width: 140px;
  }

  .ui.segment {
    box-shadow: 0px 4px 10px 10px rgba(0, 0, 0, 0.02);
    border-radius: 2px;
    border: none;
    min-width: 445px;
  }
  .save-button {
    z-index: 1;
    position: relative;
  }

  .description {
    display: inline-flex;
  }

  .ui[class*="right floated"].button,
  .ui[class*="right floated"].buttons {
    float: right;
    margin-right: 5em;
    margin-left: 0;
    margin-top: -37px;
    margin-bottom: 2em;
    color: #ffffff;
    background: $no-session-block-color;
    opacity: 1;
    width: 200px;
  }

  .pricing-labor-rates {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .retail-savings-div {
    display: inline-flex;
    text-align: center;
    width: 50%;
    font-size: 16px;
    font-weight: 600;
  }

  .retail-saving-amount-div-margin {
    margin: 0 -10%;
  }

  .retail-savings-margin {
    margin: 0 50%;
  }
  .retail-savings-empty {
    background-color: #f6f6f6;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    padding: 2px 19px;
    margin: 0 50%;
  }

  .info-Icon-retail-price {
    font-size: 10px;
    vertical-align: middle;
    left: 5px;
  }
}

.ribbon-container {
  color: white;
  margin: 10% 0 0 11%;
  font-weight: 600;
  font-size: 14px;
}

.price-popup {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  color: #1354fa;
  margin: 5px 5px;
}

.retail-popup {
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
}

.grid-centered {
  position: absolute;
  top: 73%;
  left: 49%;
  transform: translate(-20%, -50%);
}

@media screen and (max-width: 1366px) {
  .full-pricing-container {
    // .full-pricing-container .ui.column.grid > [class*="eight wide"].column, .full-pricing-container .ui.grid > .column.row > [class*="eight wide"].column, .full-pricing-container .ui.grid > .row > [class*="eight wide"].column, .full-pricing-container .ui.grid > [class*="eight wide"].column {
    //   min-width: 50% !important;
    //   margin-left: 0px !important;
    // }

    .caradvise-labor-price {
      background-color: #f6f6f6;
      border-radius: 5px;
      padding: 5px 6%;
      font-size: 16px;
      font-weight: 600;
      margin: 0 1%;
      border-radius: 5px;
      text-align: center;
    }

    .caradvise-price {
      background-color: #f6f6f6;
      border-radius: 4px;
      margin: 0 20%;
      padding: 0.4em 0.9em;
      font-size: 18px;
      font-weight: 600;
      color: $black !important;
    }

    .caradvise-labor-price-input {
      padding: 0 1%;
      text-align: center;
      flex: none;
      max-width: 25%;
      margin: 0;
      border-radius: 5px;
      color: #1354fa;
    }

    .retail-savings {
      background-color: #f6f6f6;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      padding: 1% 0;
    }

    .retail-savings-div {
      display: inline-flex;
      text-align: center;
      width: 30%;
      font-size: 16px;
      font-weight: 600;
    }

    .retail-savings-margin {
      margin: 0 20%;
    }
    .pricing-row-input {
      //padding: 3% 0;
      text-align: center;
      max-width: 50%;
      margin: 0 25%;
      flex: none;
    }
    .retail-savings-empty {
      background-color: #f6f6f6;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 600;
      padding: 2px 19px;
      margin: 0 20%;
    }

    .notifications-tr {
      margin-right: 3% !important;
    }
    .retail-saving-amount-div-margin {
      margin: 0 0;
    }
    .ui[class*="right floated"].button,
    .ui[class*="right floated"].buttons {
      margin-right: 0;
    }
  }
}
