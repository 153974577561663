// SASS variable for media query breakpoint
$breakpoint-desktop: 992px;

// Resetting default styles
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

// Fix for jumping arrows
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
}

.carousel {
  position: relative;
}

.logo-image {
  margin-top: 4%;
  max-height: 13vh;
  width: 55vh;
  position: absolute;
  z-index: 9999;
}
// Carousel slides
.carousel__slide {
  margin-right: auto;
  margin-left: auto;
  display: none;
  list-style-type: none;
  text-align: center;

  .ui.fluid.image,
  .ui.fluid.image img,
  .ui.fluid.image svg,
  .ui.fluid.images,
  .ui.fluid.images img,
  .ui.fluid.images svg {
    display: block;
    width: 100vw;
    height: 100vh;
    opacity: 0.3;
    @media screen and (max-width: 1366px) {
      height: 107vh;
    }
  }

  @media (max-width: 958px) {
    padding-right: 60px;
    padding-left: 60px;
  }

  &--active {
    display: block;
  }
}

// Content of slides
.carousel-slide__content {
  margin-bottom: 19px;
  font-size: 16px;

  @media (max-width: $breakpoint-desktop - 1px) {
    font-size: 18px;
  }
}
.carousel-slide-text {
  top: 65%;
  left: 8%;
  font-size: 23px;
  color: $white;
  position: absolute;
  line-height: 1.5;
  width: 80%;
  text-shadow: 1px 1px 3px $black;
}

.carousel-slide-title {
  top: 60%;
  left: 8%;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  color: $carousel-title;
  position: absolute;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.988235294117647);
  border-width: 2px !important;
  line-height: 1.2;
}

.carousel-slide__author,
.carousel-slide__source {
  // font-family: "Roboto", arial, sans-serif;
  font-size: 14px;

  @media (min-width: $breakpoint-desktop) {
    font-size: 16px;
  }
}

.carousel-slide__source {
  font-style: italic;
  color: $dark-gray;
}

// Carousel arrows
.carousel__arrow {
  position: absolute;
  top: 50%;
  display: block;
  color: $black;
  cursor: pointer;
  opacity: 0.75;
  transform: translateY(-50%);
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: 0.5;
  }

  &--left {
    left: 32px;
  }

  &--right {
    right: 32px;
  }
}

// Carousel indicators
.carousel__indicators {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 80%;
  left: 8%;

  li {
    &:nth-of-type(n + 2) {
      margin-left: 15px;
    }
  }
  @media screen and (max-width: 1366px) {
    top: 83%;
  }
  @media screen and (max-width: 1280px) {
    top: 85%;
  }
  @media screen and (max-width: 992px) {
    top: 87%;
  }
}

.carousel__indicator {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 2px solid $carousel-title;
  background-color: none;
  cursor: pointer;
  opacity: 5;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);

  &:hover {
    opacity: 0.5;
  }

  &--active {
    &,
    &:hover {
      opacity: 0.75;
      background-color: $white;
    }
  }
}
