/* Remove excess space between input and dropdown items  */
.ui.floating.dropdown > .menu {
  margin-top: 1px !important;
}

/*-------------------------
Shop Details Page
--------------------------*/

@media only screen and (max-width: 1080px) {
  .ui.grid > [class*="two column"].row > .column {
    width: 100% !important;
  }
  .container .ui.image {
    position: relative !important;
    width: 150px;
    height: 150px !important;
    margin: 20px;
  }
  .container .map-center {
    margin-top: 51px !important;
    width: 47%;
  }
}
@media only screen and (max-width: 720px) {
  .container .ui[class*="two column"].grid > .column:not(.row),
  .container .ui[class*="two column"].grid > .row > .column {
    width: 100% !important;
  }
  @media only screen and (max-width: 520px) {
    .container .center {
      margin: 12% 8% !important;
    }
  }
  .container .shop-info {
    display: inline-block !important;
  }
}
.container .ui.fluid.card {
  margin: 0px !important;
}
.wrapper,
.container,
.col-1,
.col-2 {
  padding: 10px !important;
}
.shop {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  padding: 20px;
  border-radius: 50%;
  font-size: 19px;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #002d5e;
  background-color: #fff;
  font-family: "Montserrat", sans-serif !important;
}
.sidebar.close .shop {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 19px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #002d5e;
  background-color: #fff;
  padding: 20px;
}
// .shop:hover .imgSelect p{
//   top: 50%;
//   transform: translateY(-50%);
// }
.shop .column ul li {
  margin-top: 0.5rem;
}
.imgSelect {
  padding: 1rem 0 0 1rem;
  display: flex;
  align-items: center;
}
.imgSelect span {
  margin-left: 1rem;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebar .logo-details {
  width: 100% !important;
  display: flex;
  align-items: center;
  left: 0%;
  position: relative;
}
.sidebar.close .imgSelect span {
  opacity: 0;
  pointer-events: none;
  display: none;
}
.sidebar.close .sub-menu {
  opacity: 0;
  pointer-events: none;
}

.sidebar.close .profile-details {
  width: 57px !important;
  border-radius: 5rem;
  height: 57px;
}
.sidebar.close .profile-details,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details {
  display: block !important;
}

.sidebar.close .name-job .my-account-name {
  display: none !important;
}
.check-logo {
  display: none !important;
}
.sidebar.close .check-logo {
  display: block !important;
  position: fixed;
  top: 10px;
  left: 0px;
}

.sidebar.close .logo-details {
  // pointer-events: none;
  width: 68px !important;
  margin-left: 0px !important;
  left: 0 !important;
  display: none;
}

.sidebar .profile-details .profile-content {
  margin-left: 1rem;
}
.my-account-name {
  margin: 24px 15px 0 -15px !important;
}
.container .map-dimensions {
  height: 200px;
  margin-right: 20px;
  margin-left: 32px;
}

@media only screen and (max-width: 1366px) {
  .container .map-dimensions {
    height: 150px !important;
    margin-left: 30px !important;
  }
  .container .image-dimensions {
    height: 150px !important;
  }
}
@media only screen and (max-width: 1366px) {
  .container .column {
    font-size: 12px !important;
  }
}
.sidebar.close .profile-details {
  background: none !important;
}
.sidebar.close .profile-initials {
  position: relative;
  bottom: 20px;
  height: 40px !important;
  width: 40px !important;
  padding: 10px !important;
}

/*-------------------------
Shop Orders Page
--------------------------*/

.full-shop-order-container {
  padding: 10px 10px !important;
}
@media only screen and (max-width: 1366px) {
  .full-shop-order-container .ui.button {
    margin: 0px 6px 0 5px;
    font-size: 12px !important;
  }
}
@media screen and (min-width: 720px) and (max-width: 1024px) {
  .full-shop-order-container .div-card {
    height: 200px !important;
  }
  .ui.column.grid > [class*="four wide"].column,
  .ui.grid > .column.row > [class*="four wide"].column,
  .ui.grid > .row > [class*="four wide"].column,
  .ui.grid > [class*="four wide"].column {
    width: 50% !important;
    display: flex !important;
    align-items: center !important;
  }
  .ui.column.grid > [class*="five wide"].column,
  .ui.grid > .column.row > [class*="five wide"].column,
  .ui.grid > .row > [class*="five wide"].column,
  .ui.grid > [class*="five wide"].column {
    width: 50% !important;
  }
  .ui.column.grid > [class*="three wide"].column,
  .ui.grid > .column.row > [class*="three wide"].column,
  .ui.grid > .row > [class*="three wide"].column,
  .ui.grid > [class*="three wide"].column {
    width: 50% !important;
  }
  .full-shop-order-container .div-progress {
    margin: 0px 0px 0px 0px !important;
    width: 144px;
    transform: translate(-130px, -5px);
  }
  .full-shop-order-container .action-buttons {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
  .full-shop-order-container .ui.huge.button {
    width: 78px !important;
    height: 50px;
    padding: 10px !important;
  }
  .ui .card {
    width: 287px !important;
  }
  .full-shop-order-container .ui.celled.grid > .column:not(.row),
  .full-shop-order-container .ui.celled.grid > .row > .column {
    box-shadow: none !important;
    outline: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 719px) {
  .full-shop-order-container .div-card {
    height: 370px !important;
  }
  .ui.column.grid > [class*="four wide"].column,
  .ui.grid > .column.row > [class*="four wide"].column,
  .ui.grid > .row > [class*="four wide"].column,
  .ui.grid > [class*="four wide"].column {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
  }
  .ui.column.grid > [class*="five wide"].column,
  .ui.grid > .column.row > [class*="five wide"].column,
  .ui.grid > .row > [class*="five wide"].column,
  .ui.grid > [class*="five wide"].column {
    width: 100% !important;
  }
  .ui.column.grid > [class*="three wide"].column,
  .ui.grid > .column.row > [class*="three wide"].column,
  .ui.grid > .row > [class*="three wide"].column,
  .ui.grid > [class*="three wide"].column {
    width: 100% !important;
  }
  .full-shop-order-container .div-progress {
    margin: 0px 0px 0px 0px !important;
    width: 144px;
    position: absolute !important;
    top: 0 !important;
  }
  .full-shop-order-container .action-buttons {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
  .ui.column.grid > [class*="seven wide"].column,
  .ui.grid > .column.row > [class*="seven wide"].column,
  .ui.grid > .row > [class*="seven wide"].column,
  .ui.grid > [class*="seven wide"].column {
    width: 100% !important;
  }
}

.full-shop-order-container .div-table {
  height: 50vh !important;
}

.full-shop-order-container .bottom-right {
  background: #fff;
  padding: 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .ui.form .fields {
    flex-wrap: initial !important;
  }
}
@media only screen and (max-width: 720px) {
  .mobView {
    margin-left: 30px;
  }
}

.home-content {
  align-items: flex-start !important;
}
.ui .card span {
  padding-top: 5px;
  padding-left: 5px;
}
.ui.column.grid > [class*="seven wide"].column,
.ui.grid > .column.row > [class*="seven wide"].column,
.ui.grid > .row > [class*="seven wide"].column,
.ui.grid > [class*="seven wide"].column {
  width: 46.75% !important;
}
.full-shop-order-container .action-buttons {
  justify-content: end;
}
/*-------------------------
  Home
--------------------------*/

.full-dashboard-container .ui.card {
  margin: 30px 10px 0 10px !important;
  width: 98% !important;
}

// .full-dashboard-container.ui.column.grid>[class*="thirteen wide"].column, .ui.grid>.column.row>[class*="thirteen wide"].column, .ui.grid>.row>[class*="thirteen wide"].column, .ui.grid>[class*="thirteen wide"].column {
//   width: 100%!important;
// }
// .ui.column.grid>[class*="three wide"].column, .ui.grid>.column.row>[class*="three wide"].column, .ui.grid>.row>[class*="three wide"].column, .ui.grid>[class*="three wide"].column {
//   width: 100%!important;
// }
.ui.column.grid > [class*="thirteen wide"].column,
.ui.grid > .column.row > [class*="thirteen wide"].column,
.ui.grid > .row > [class*="thirteen wide"].column,
.ui.grid > [class*="thirteen wide"].column {
  width: 100% !important;
}
.badge {
  border-radius: 10rem;
  background-color: $no-session-block-color;
  color: $primary-button-text-color;
  font-size: 22px;
  padding: 3px 6px;
  margin-left: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
}
.d-flex {
  display: flex !important;
}
.full-dashboard-container .dash-table {
  max-height: 44vh !important;
}
// .full-pricing-container .ui.grid [class*="right floated"].column {
//   margin-left: 0px !important;
//   width: 100% !important;
// }
// .home-content {
//   z-index: 999;
//   position: relative;
// }

@media screen and (max-width: 1366px) {
  // .full-pricing-container .ui.column.grid > [class*="eight wide"].column, .full-pricing-container .ui.grid > .column.row > [class*="eight wide"].column, .full-pricing-container .ui.grid > .row > [class*="eight wide"].column, .full-pricing-container .ui.grid > [class*="eight wide"].column {
  //     min-width: 100% !important;
  //     margin-left: 0px !important;
  // }
}
.full-pricing-container {
  margin: 0 0 0 0% !important;
  width: 100% !important;
}

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) tbody,
  .ui.table:not(.unstackable) tr,
  .ui.table:not(.unstackable) tr > td,
  .ui.table:not(.unstackable) tr > th {
    width: auto !important;
    display: inline-block !important;
  }
  .borderless td {
    min-width: 110px;
  }
}

.full-pricing-container .save-button {
  z-index: 1;
  position: fixed !important;
  bottom: 0rem;
  right: 0rem;
}

/*-------------------------
  Customer Feedback
--------------------------*/

.full-feedback-container .ui.card {
  margin: 20px 10px 0 20px !important;
  width: 99% !important;
}

.ui.column.grid > [class*="twelve wide"].column,
.ui.grid > .column.row > [class*="twelve wide"].column,
.ui.grid > .row > [class*="twelve wide"].column,
.ui.grid > [class*="twelve wide"].column {
  width: 100% !important;
  margin-top: 20px;
}
.full-feedback-container .rating-summary {
  text-align: center;
  margin-left: 0px !important;
}
.full-feedback-container {
  display: contents;
}
.full-feedback-container .div-filters,
.div-filters-active {
  width: auto !important;
}
.full-feedback-container .rating-summary {
  margin-right: 2rem;
}

@media only screen and (max-width: 638px) {
  .d-block {
    display: block;
    margin-top: 1rem;
  }
  .full-shop-order-container .personal-details {
    margin-top: 0px !important;
  }
}

.settings {
  position: absolute;
  border: 1px solid #b5b3b3;
  border-radius: 6px;
  min-width: 70%;
  padding-top: 20px;
  top: 80px;
  left: 40px;
}
