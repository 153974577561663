.show-note {
  display: inline-flex;
  width: 100%;
  border-radius: 10px;
  margin-top: 5px;
  cursor: pointer;
}

.notes-title {
  margin-top: -5px;
  overflow: hidden;
  width: 190px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notes-summary {
  font-size: 9px;
  color: $gray;
}

.show-notes-container {
  max-height: 28vh;
  width: 100%;
  overflow-y: scroll;
}

.add-note-button {
  color: black;
  display: inline-flex;
  width: auto;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-top: 15px;

  .add-note-text {
    overflow: hidden;
    width: 175px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 1px;
  }
}

.add-note-icon {
  font-size: 15px;
  margin: 5px;
  color: $override-link-color;
}

.note-comment {
  margin: 10px;
  height: 30vh;
  background-color: #fbfbfb;
}
