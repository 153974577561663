@keyframes moveToRight {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}

.progress-outer {
  display: flex;
  align-items: center;
  transform: rotate(-90deg);
  width: 120px;
}
.progress-info {
  width: 30px;
  padding-left: 5px;
}

.progress {
  width: 100%;
  background: #eee;
  min-height: 1px;
  position: absolute;
  overflow: hidden;
  border-radius: 50px;
}

.progress.progress--small {
  height: 3px;
}

.progress.progress--medium {
  height: 15px;
  margin-bottom: 50px;
  border-radius: 52px;
  margin-left: -75px;
}

.progress.progress--large {
  height: 15px;
  margin-left: -75px;
  margin-top: 40px;
}
.progress-bar {
  background: lime;
  height: 100px;
  left: 10px;
}
