@import "variables";

$ntw-primary: #005ca9;
$ntw-color: #fdba30;
$ntw-hovar-color: #024379;

/* ***** Glossary colors: ***** */
$ntw-yellow: #fdba30;
$ntw-yellow-hover: #cc9527;
$ntw-red: #dd1d21;
$ntw-red-hover: #a51518;
$ntw-dark-blue: #005ca9;
$ntw-dark-blue-hover: #024379;
$ntw-gray: $light-gray;

$primary-color: $ntw-primary;
$primary-color-hover: $ntw-hovar-color;
$light-blue: $ntw-color;
$primary-button-color: $ntw-color;
$primary-button-hover-color: $ntw-hovar-color;
$primary-button-text-color: $ntw-color;
$header-text-color: $ntw-primary;
$primary-button-color: $ntw-color;
$primary-button-hover-color: $ntw-hovar-color;
$secondary-button-text-color: $ntw-color;
$recharts-primary-color: $ntw-color;
$recharts-secondary-color: $ntw-color;
$primary-color-light: #fef5cd;
$no-session-block-color: $ntw-gray;
/* ***** Glossary colors: ***** */
$ntw-yellow: #fdba30;
$ntw-yellow-hover: #cc9527;
$ntw-red: #dd1d21;
$ntw-red-hover: #a51518;
$ntw-dark-blue: #005ca9;
$ntw-dark-blue-hover: #024379;
$ntw-gray: #595959;
$button-hover-color: $ntw-color;
/* **************************** */

$secondary-color: $ntw-red;

$primary-button-color: $ntw-dark-blue;
$primary-button-hover-color: $ntw-color;
$primary-link-color: $ntw-primary;
$override-link-color: $ntw-primary;
$override-link-hover-color: $ntw-hovar-color;
$carousel-title: $ntw-color;
$active-icon-color: $ntw-color;
