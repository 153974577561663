/* To add more CarAdvise styling, see https://caradvise.atlassian.net/wiki/spaces/PR/pages/21332001/Digital+Components?preview=/21332001/21200917/CarAdvise_DigitalComponents.pdf */
/* Fixed Color Scheme */
/* Color classes */
.ca-very-light-gray {
  color: #f7f7f7; }

.background-very-light-gray {
  background-color: #f7f7f7; }

.ca-light-gray {
  color: #efefef; }

.ca-gray {
  color: #dddddd; }

.ca-dark-gray {
  color: #474747; }

.ca-darkest-gray {
  color: #414447; }

.ca-orange {
  color: #f8991d; }

.ca-blue {
  color: #0390ff; }

.ca-navy-blue {
  color: #002d5e; }

.ca-caradvise-electric-blue {
  color: #0390ff; }

.ca-light-green {
  color: #8dd68a; }

.ca-red {
  color: #ff0000; }

/* ***** Glossary colors: ***** */
/* ***** Glossary colors: ***** */
/* **************************** */
/* Remove excess space between input and dropdown items  */
.ui.floating.dropdown > .menu {
  margin-top: 1px !important; }

/*-------------------------
Shop Details Page
--------------------------*/
@media only screen and (max-width: 1080px) {
  .ui.grid > [class*="two column"].row > .column {
    width: 100% !important; }
  .container .ui.image {
    position: relative !important;
    width: 150px;
    height: 150px !important;
    margin: 20px; }
  .container .map-center {
    margin-top: 51px !important;
    width: 47%; } }

@media only screen and (max-width: 720px) {
  .container .ui[class*="two column"].grid > .column:not(.row),
  .container .ui[class*="two column"].grid > .row > .column {
    width: 100% !important; } }

@media only screen and (max-width: 720px) and (max-width: 520px) {
  .container .center {
    margin: 12% 8% !important; } }

@media only screen and (max-width: 720px) {
  .container .shop-info {
    display: inline-block !important; } }

.container .ui.fluid.card {
  margin: 0px !important; }

.wrapper,
.container,
.col-1,
.col-2 {
  padding: 10px !important; }

.shop {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  padding: 20px;
  border-radius: 50%;
  font-size: 19px;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #002d5e;
  background-color: #fff;
  font-family: "Montserrat", sans-serif !important; }

.sidebar.close .shop {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 19px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #002d5e;
  background-color: #fff;
  padding: 20px; }

.shop .column ul li {
  margin-top: 0.5rem; }

.imgSelect {
  padding: 1rem 0 0 1rem;
  display: flex;
  align-items: center; }

.imgSelect span {
  margin-left: 1rem;
  font-size: 15px;
  line-height: 15px;
  font-weight: 700;
  color: #fff;
  transition: all 0.4s ease; }

.sidebar .logo-details {
  width: 100% !important;
  display: flex;
  align-items: center;
  left: 0%;
  position: relative; }

.sidebar.close .imgSelect span {
  opacity: 0;
  pointer-events: none;
  display: none; }

.sidebar.close .sub-menu {
  opacity: 0;
  pointer-events: none; }

.sidebar.close .profile-details {
  width: 57px !important;
  border-radius: 5rem;
  height: 57px; }

.sidebar.close .profile-details,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details {
  display: block !important; }

.sidebar.close .name-job .my-account-name {
  display: none !important; }

.check-logo {
  display: none !important; }

.sidebar.close .check-logo {
  display: block !important;
  position: fixed;
  top: 10px;
  left: 0px; }

.sidebar.close .logo-details {
  width: 68px !important;
  margin-left: 0px !important;
  left: 0 !important;
  display: none; }

.sidebar .profile-details .profile-content {
  margin-left: 1rem; }

.my-account-name {
  margin: 24px 15px 0 -15px !important; }

.container .map-dimensions {
  height: 200px;
  margin-right: 20px;
  margin-left: 32px; }

@media only screen and (max-width: 1366px) {
  .container .map-dimensions {
    height: 150px !important;
    margin-left: 30px !important; }
  .container .image-dimensions {
    height: 150px !important; } }

@media only screen and (max-width: 1366px) {
  .container .column {
    font-size: 12px !important; } }

.sidebar.close .profile-details {
  background: none !important; }

.sidebar.close .profile-initials {
  position: relative;
  bottom: 20px;
  height: 40px !important;
  width: 40px !important;
  padding: 10px !important; }

/*-------------------------
Shop Orders Page
--------------------------*/
.full-shop-order-container {
  padding: 10px 10px !important; }

@media only screen and (max-width: 1366px) {
  .full-shop-order-container .ui.button {
    margin: 0px 6px 0 5px;
    font-size: 12px !important; } }

@media screen and (min-width: 720px) and (max-width: 1024px) {
  .full-shop-order-container .div-card {
    height: 200px !important; }
  .ui.column.grid > [class*="four wide"].column,
  .ui.grid > .column.row > [class*="four wide"].column,
  .ui.grid > .row > [class*="four wide"].column,
  .ui.grid > [class*="four wide"].column {
    width: 50% !important;
    display: flex !important;
    align-items: center !important; }
  .ui.column.grid > [class*="five wide"].column,
  .ui.grid > .column.row > [class*="five wide"].column,
  .ui.grid > .row > [class*="five wide"].column,
  .ui.grid > [class*="five wide"].column {
    width: 50% !important; }
  .ui.column.grid > [class*="three wide"].column,
  .ui.grid > .column.row > [class*="three wide"].column,
  .ui.grid > .row > [class*="three wide"].column,
  .ui.grid > [class*="three wide"].column {
    width: 50% !important; }
  .full-shop-order-container .div-progress {
    margin: 0px 0px 0px 0px !important;
    width: 144px;
    transform: translate(-130px, -5px); }
  .full-shop-order-container .action-buttons {
    margin-top: 0px !important;
    margin-left: 10px !important; }
  .full-shop-order-container .ui.huge.button {
    width: 78px !important;
    height: 50px;
    padding: 10px !important; }
  .ui .card {
    width: 287px !important; }
  .full-shop-order-container .ui.celled.grid > .column:not(.row),
  .full-shop-order-container .ui.celled.grid > .row > .column {
    box-shadow: none !important;
    outline: none; } }

@media screen and (min-width: 320px) and (max-width: 719px) {
  .full-shop-order-container .div-card {
    height: 370px !important; }
  .ui.column.grid > [class*="four wide"].column,
  .ui.grid > .column.row > [class*="four wide"].column,
  .ui.grid > .row > [class*="four wide"].column,
  .ui.grid > [class*="four wide"].column {
    width: 100% !important;
    display: flex !important;
    align-items: center !important; }
  .ui.column.grid > [class*="five wide"].column,
  .ui.grid > .column.row > [class*="five wide"].column,
  .ui.grid > .row > [class*="five wide"].column,
  .ui.grid > [class*="five wide"].column {
    width: 100% !important; }
  .ui.column.grid > [class*="three wide"].column,
  .ui.grid > .column.row > [class*="three wide"].column,
  .ui.grid > .row > [class*="three wide"].column,
  .ui.grid > [class*="three wide"].column {
    width: 100% !important; }
  .full-shop-order-container .div-progress {
    margin: 0px 0px 0px 0px !important;
    width: 144px;
    position: absolute !important;
    top: 0 !important; }
  .full-shop-order-container .action-buttons {
    margin-top: 0px !important;
    margin-left: 10px !important; }
  .ui.column.grid > [class*="seven wide"].column,
  .ui.grid > .column.row > [class*="seven wide"].column,
  .ui.grid > .row > [class*="seven wide"].column,
  .ui.grid > [class*="seven wide"].column {
    width: 100% !important; } }

.full-shop-order-container .div-table {
  height: 50vh !important; }

.full-shop-order-container .bottom-right {
  background: #fff;
  padding: 5px;
  border-radius: 5px; }

@media only screen and (max-width: 767px) {
  .ui.form .fields {
    flex-wrap: initial !important; } }

@media only screen and (max-width: 720px) {
  .mobView {
    margin-left: 30px; } }

.home-content {
  align-items: flex-start !important; }

.ui .card span {
  padding-top: 5px;
  padding-left: 5px; }

.ui.column.grid > [class*="seven wide"].column,
.ui.grid > .column.row > [class*="seven wide"].column,
.ui.grid > .row > [class*="seven wide"].column,
.ui.grid > [class*="seven wide"].column {
  width: 46.75% !important; }

.full-shop-order-container .action-buttons {
  justify-content: end; }

/*-------------------------
  Home
--------------------------*/
.full-dashboard-container .ui.card {
  margin: 30px 10px 0 10px !important;
  width: 98% !important; }

.ui.column.grid > [class*="thirteen wide"].column,
.ui.grid > .column.row > [class*="thirteen wide"].column,
.ui.grid > .row > [class*="thirteen wide"].column,
.ui.grid > [class*="thirteen wide"].column {
  width: 100% !important; }

.badge {
  border-radius: 10rem;
  background-color: #efefef;
  color: #fdba30;
  font-size: 22px;
  padding: 3px 6px;
  margin-left: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px; }

.d-flex {
  display: flex !important; }

.full-dashboard-container .dash-table {
  max-height: 44vh !important; }

.full-pricing-container {
  margin: 0 0 0 0% !important;
  width: 100% !important; }

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) tbody,
  .ui.table:not(.unstackable) tr,
  .ui.table:not(.unstackable) tr > td,
  .ui.table:not(.unstackable) tr > th {
    width: auto !important;
    display: inline-block !important; }
  .borderless td {
    min-width: 110px; } }

.full-pricing-container .save-button {
  z-index: 1;
  position: fixed !important;
  bottom: 0rem;
  right: 0rem; }

/*-------------------------
  Customer Feedback
--------------------------*/
.full-feedback-container .ui.card {
  margin: 20px 10px 0 20px !important;
  width: 99% !important; }

.ui.column.grid > [class*="twelve wide"].column,
.ui.grid > .column.row > [class*="twelve wide"].column,
.ui.grid > .row > [class*="twelve wide"].column,
.ui.grid > [class*="twelve wide"].column {
  width: 100% !important;
  margin-top: 20px; }

.full-feedback-container .rating-summary {
  text-align: center;
  margin-left: 0px !important; }

.full-feedback-container {
  display: contents; }

.full-feedback-container .div-filters,
.div-filters-active {
  width: auto !important; }

.full-feedback-container .rating-summary {
  margin-right: 2rem; }

@media only screen and (max-width: 638px) {
  .d-block {
    display: block;
    margin-top: 1rem; }
  .full-shop-order-container .personal-details {
    margin-top: 0px !important; } }

.settings {
  position: absolute;
  border: 1px solid #b5b3b3;
  border-radius: 6px;
  min-width: 70%;
  padding-top: 20px;
  top: 80px;
  left: 40px; }

.button-primary {
  background-color: #005ca9 !important; }

.no-session-button {
  padding: 0.8vw 2vw !important; }

.ui.primary.button,
.ui.primary.buttons .button {
  border-radius: 50px !important; }

@media screen and (max-width: 1366px) {
  .no-session-button {
    padding: 1vw 3vw !important; } }

@media screen and (max-width: 958px) {
  .no-session-button {
    padding: 1.5vw 3vw !important; } }

@media screen and (max-width: 758px) {
  .no-session-button {
    padding: 2vw 3vw !important; } }

@media screen and (max-width: 500px) {
  .no-session-button {
    padding: 3vw 5vw !important; } }

body {
  margin: 0;
  padding: 0;
  color: #666666; }

.app {
  display: flex;
  background-color: #EFEFEF;
  min-height: 100vh; }

.flex-container {
  display: flex;
  flex: 1;
  align-items: center; }

.no-session-container {
  display: flex;
  flex: 1;
  min-height: 100vh;
  overflow: hidden; }
  .no-session-container .no-session-cursor-pointer {
    display: none; }
  .no-session-container .no-session-logo-region-small {
    display: none; }
  .no-session-container .no-session-logo-region {
    background-color: #efefef;
    width: 50%;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative; }
    .no-session-container .no-session-logo-region .no-session-logo {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%); }
  .no-session-container .no-session-container-header {
    display: none; }
  .no-session-container .no-session-carousel {
    background-color: #efefef;
    width: 50%;
    display: flex; }
  .no-session-container .wrapper-1 {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column; }
  .no-session-container .wrapper-2 {
    padding: 30px;
    text-align: center; }
  .no-session-container .thank-you-title {
    font-size: 4em;
    font-weight: 900;
    letter-spacing: 3px;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif !important; }
  .no-session-container .wrapper-2 p {
    margin: 40px 0 0 0;
    font-weight: 600;
    font-size: 1.3em;
    letter-spacing: 1px;
    line-height: 1.5; }
  .no-session-container .welcome-sign-out {
    border: none;
    margin: 30px 0;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif !important; }
  @media (min-width: 360px) {
    .no-session-container .thank-you-title {
      font-size: 4.5em; }
    .no-session-container .go-home {
      margin-bottom: 20px; } }
  @media (min-width: 600px) {
    .no-session-container .thankyoucontent {
      max-width: 1000px;
      margin: 0 auto; }
    .no-session-container .wrapper-1 {
      height: initial;
      max-width: 620px;
      margin: 0 auto;
      margin-top: 50px; } }
  .no-session-container .no-session-content-container {
    width: 50%;
    min-height: 100vh;
    background-color: #ffffff;
    flex: 1;
    display: flex; }
    .no-session-container .no-session-content-container .no-session-content {
      padding: 2vh 10vw;
      width: 100%; }
      .no-session-container .no-session-content-container .no-session-content .no-session-content-header {
        display: inline-flex;
        min-height: 8vh;
        flex-direction: row;
        justify-content: flex-end;
        font-weight: 800;
        color: #000000;
        position: relative !important;
        align-items: center; }
      .no-session-container .no-session-content-container .no-session-content .no-session-form {
        padding-top: 5vh; }
      .no-session-container .no-session-content-container .no-session-content .no-session-content-footer {
        text-align: center;
        padding-top: 7vh; }
        .no-session-container .no-session-content-container .no-session-content .no-session-content-footer .no-session-content-footer-highlight {
          padding: 20px;
          border: 1px solid #dddddd;
          border-radius: 5px;
          background-color: #efefef; }
    .no-session-container .no-session-content-container label.label {
      color: #005ca9; }

@media screen and (max-width: 958px) {
  .no-session-container {
    display: block;
    overflow: hidden; }
    .no-session-container .no-session-cursor-pointer {
      cursor: pointer;
      display: block;
      position: absolute;
      padding: 20px 10px;
      z-index: 999; }
    .no-session-container .no-session-logo-region {
      display: none; }
    .no-session-container .no-session-logo-region-small {
      display: flex;
      background-color: #efefef;
      width: 100%;
      justify-content: center;
      align-items: center;
      max-height: 12vh; }
      .no-session-container .no-session-logo-region-small .no-session-logo-small {
        margin: 8px 0 0 0;
        min-height: 12vh;
        text-align: center; }
    .no-session-container .no-session-container-header {
      display: block; }
    .no-session-container .no-session-carousel {
      display: none; }
    .no-session-container .no-session-content-container {
      width: 100%;
      min-height: 88vh;
      background-color: #ffffff; }
      .no-session-container .no-session-content-container .no-session-content {
        padding: 1.5vh 10vw; }
        .no-session-container .no-session-content-container .no-session-content .no-session-content-header {
          display: inline-block;
          min-height: 5vh;
          justify-content: center;
          align-content: center;
          position: relative; }
        .no-session-container .no-session-content-container .no-session-content .no-session-form {
          padding-top: 2vh; }
          .no-session-container .no-session-content-container .no-session-content .no-session-form .password-spacing .ui.form .field {
            clear: both;
            margin: 0 0 2em !important; } }

.full-dashboard-container .ui.card {
  box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
  margin: 60px 10px 0 50px;
  width: 95%;
  border-radius: 15px; }

.full-dashboard-container .ui.active.button {
  z-index: 10; }

.full-dashboard-container .table-header {
  font-weight: bold;
  color: black;
  font-family: "Montserrat", sans-serif !important; }

.full-dashboard-container .dash-table {
  border-collapse: collapse;
  overflow-y: scroll;
  min-height: 25vh;
  max-height: 28vh; }

.full-dashboard-container .item-count {
  color: #005ca9;
  font-size: 40px; }

.full-dashboard-container .ui.table {
  border: 0px !important; }

.full-dashboard-container .ui.table thead tr:first-child > th {
  position: sticky;
  top: 0;
  z-index: 2; }

.full-dashboard-container .ui.table thead th {
  cursor: default;
  border-bottom: 0px !important; }

.full-dashboard-container .ui.table tr td {
  border-top: 0px !important; }

.full-dashboard-container .adjust-button {
  padding: 5px;
  font-size: 15px;
  float: right;
  text-align: center;
  border-radius: 20px;
  background-color: #a3a2b3;
  color: whitesmoke; }

.full-dashboard-container .pricing-summary {
  position: relative;
  text-align: center;
  width: 100%;
  padding: 50px 20px;
  margin-top: 10px; }

.full-dashboard-container .div-center {
  font-size: 20px;
  font-weight: 700;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.full-dashboard-container .no-content {
  position: absolute;
  font-size: 20px;
  font-weight: 700;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.full-dashboard-container .view-all-button {
  color: #474747;
  position: absolute;
  right: 20px;
  text-decoration: underline; }

.full-dashboard-container .expand-button {
  width: 100px;
  height: 30px;
  padding: 5px;
  font-size: 15px;
  float: right;
  text-align: center;
  border-radius: 20px;
  background-color: #a3a2b3;
  color: whitesmoke; }

.full-dashboard-container .pricing-error-msg {
  color: red;
  position: relative;
  font-size: 11px; }

@media only screen and (max-width: 1366px) {
  .full-dashboard-container .full-dashboard-container {
    width: 80%; } }

.full-dashboard-container .ui.icon.input > input {
  color: #a8b1ba;
  background: #f2f4f4; }
  .full-dashboard-container .ui.icon.input > input .ui.icon.input > i.icon:not(.link) {
    color: #a3a2b3; }

.full-dashboard-container .ui.icon.input > input:focus {
  background: white; }
  .full-dashboard-container .ui.icon.input > input:focus .ui.icon.input > i.icon:not(.link) {
    color: #b0b0b5; }

.full-dashboard-container .ui.icon.input > i.icon:after,
.full-dashboard-container .ui.icon.input > i.icon:before {
  color: #b0b0b5; }

.blue-bar {
  display: flex;
  flex-direction: column;
  width: 250px;
  background-color: #005ca9;
  min-height: 100vh;
  height: 100%;
  border-radius: 10px;
  position: fixed; }

.blue-bar-top {
  flex: 1; }

.blue-bar-bottom {
  display: block;
  flex-direction: column-reverse; }

.navbar-items {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .navbar-items li {
    padding: 20px; }

.navbar-container {
  display: flex;
  flex-direction: column;
  background-color: #005ca9;
  padding-top: 15px;
  min-height: 100vh;
  align-items: center;
  box-shadow: -1px 2px 1px -1px black;
  z-index: 2;
  min-width: 260px; }

.active-nav-link {
  background-color: #024379;
  font-weight: bold; }
  .active-nav-link .fontawesome-icons {
    color: #fdba30; }

.menu-div {
  margin-top: 20px; }

.menu-items {
  list-style-type: none;
  padding: 0;
  width: 250px;
  font-size: 15px;
  color: #f7fbff; }
  .menu-items a {
    padding: 15px 0px 15px 15px;
    text-decoration: none;
    color: white;
    display: flex;
    align-content: center; }
  .menu-items li {
    padding: 5px;
    list-style-type: none; }

.logo-check {
  position: relative;
  top: 20px;
  left: 40px;
  width: 150px; }

.sign-out {
  display: none;
  position: absolute;
  left: 32px;
  bottom: -5px;
  width: 240px;
  height: 60px;
  text-align: center;
  background-size: contain; }

.sign-out a {
  display: block;
  padding-right: 40px;
  margin-top: 20px; }

.sign-out:hover {
  display: block; }

.my-account {
  position: relative;
  bottom: 20px;
  left: 15px; }

.my-account-name {
  width: 180px;
  margin: 24px 15px 0 15px;
  font-size: 15px;
  color: #f7fbff;
  font-weight: bold;
  justify-content: center;
  align-items: center; }

.my-account-link {
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #002d5e;
  background-color: #fff;
  text-decoration: none;
  padding: 10px;
  border-radius: 20px;
  transform: translateX(10%); }

.my-account-link:hover {
  color: #fff;
  background-color: #dde2e6; }

.my-account-link:hover + .sign-out {
  display: block; }

.div-shop {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px; }
  .div-shop .my-shop {
    width: 40px;
    height: 40px;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #002d5e;
    background-color: #fff;
    margin: 0 15px;
    padding: 10px 13px;
    border-radius: 20px;
    transform: translateX(-12%); }

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #005ca9;
  z-index: 100;
  transition: all 0.5s ease; }

.sidebar.close {
  width: 68px; }

.sidebar .logo-details {
  height: 60px;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.sidebar .logo-details i {
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px; }

.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
  margin-top: 0px; }

.sidebar.close .check-logo {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s; }

.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none; }

.sidebar .nav-links {
  height: 100%;
  padding: 20px 0 150px 0;
  overflow: auto; }

.sidebar.close .nav-links {
  overflow: visible; }

.sidebar .nav-links::-webkit-scrollbar {
  display: none; }

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease; }

.sidebar .nav-links li:hover {
  background: #024379; }

.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.sidebar.close .nav-links li .iocn-link {
  display: block; }

.logout {
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease; }

.fontawesome-icons {
  height: 44px;
  min-width: 55px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  writing-mode: vertical-rl; }

.fa-calendar:before {
  font-size: 18px; }

.fa-comments:before {
  font-size: 18px; }

.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none; }

.sidebar .nav-links li a .link_name {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease; }

.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none; }

.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none; }

.sidebar .nav-links li.showMenu .sub-menu {
  display: block; }

.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease; }

.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1; }

.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s; }

.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease; }

.sidebar .nav-links li .sub-menu .link_name {
  display: none; }

.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block; }

.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none; }

.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%); }

.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #005ca9;
  padding: 12px 0;
  transition: all 0.5s ease; }

.sidebar.close .profile-details {
  background: white; }

.sidebar.close .profile-details {
  width: 78px; }

.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center; }

.sidebar .profile-details .profile-initials {
  height: 40px;
  width: 40px;
  font-size: 15px;
  font-weight: bold;
  color: #002d5e;
  background-color: #fff;
  text-decoration: none;
  padding: 10px;
  text-align: center;
  border-radius: 50px;
  transition: all 0.6s ease; }

.sidebar.close .profile-details .profile-initials {
  height: 40px;
  width: 40px;
  font-size: 15px;
  font-weight: bold;
  color: #002d5e;
  background-color: #fff;
  text-decoration: none;
  padding: 10px;
  text-align: center;
  border-radius: 50px;
  transition: all 0.5s ease; }

.sidebar .profile-details .profile_name,
.sidebar .profile-details {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap; }

.sidebar.close .profile-details,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details {
  display: none; }

.sidebar .profile-details {
  font-size: 12px; }

.home-section {
  position: relative;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  padding: 12px; }

.sidebar.close ~ .home-section {
  left: 68px;
  width: calc(100% - 68px); }

.home-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.home-section .home-content .bx-menu,
.home-section .home-content .main-text {
  color: #474747;
  font-size: 35px; }

.home-section .home-content .bx-menu {
  cursor: pointer;
  margin-right: 10px; }

.home-section .home-content .main-text {
  font-size: 26px;
  font-weight: 600; }

.home-section select#lang {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 130px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  cursor: pointer;
  outline: none !important;
  border-bottom: 2px solid #006f9f;
  z-index: 2; }

@media screen and (max-width: 400px) {
  .sidebar {
    width: 240px; }
  .sidebar.close {
    width: 78px; }
  .sidebar .profile-details {
    width: 230px; }
  .sidebar.close .profile-details {
    background: none; }
  .sidebar.close .profile-details {
    width: 78px; }
  .home-section {
    left: 240px;
    width: calc(100% - 240px); }
  .sidebar.close ~ .home-section {
    left: 78px;
    width: calc(100% - 78px); } }

.main {
  flex: 1;
  height: auto;
  width: 100;
  margin-left: 220px; }

.shop-header {
  display: flex;
  padding: 3vh 0;
  width: 100%;
  height: 40px;
  margin: 0 80px 0 65px;
  font-size: 30px;
  font-weight: bold;
  color: #474747; }

.page-header {
  display: flex;
  font-family: "Montserrat", sans-serif !important;
  font-size: 23px;
  padding: 7px 0;
  width: 100%;
  font-weight: bold;
  color: #474747; }
  .page-header .ui.selection.dropdown {
    cursor: pointer;
    min-height: 1em;
    background: transparent !important;
    padding: 0em 2.1em 0.78571429em 1em !important;
    border: none !important; }
  .page-header .ui.selection.dropdown > .delete.icon,
  .page-header .ui.selection.dropdown > .dropdown.icon,
  .page-header .ui.selection.dropdown > .search.icon {
    top: 0 !important; }

.svg-inline--fa {
  display: inline-block;
  font-size: 30px;
  height: 2em;
  vertical-align: -0.125em;
  position: relative; }

.svg-inline--fa.fa-w-11 {
  color: white;
  margin-left: 220px;
  padding-top: 20px; }

.svg-inline--fa.fa-w-14 {
  padding-top: 20px; }

@media only screen and (max-width: 567px) {
  .main {
    flex: 1;
    height: auto;
    width: 100;
    margin-left: 20px; } }

.full-container {
  margin-top: 30px; }
  .full-container .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 60px 10px 0 50px;
    width: 95%;
    border-radius: 15px; }
  .full-container .expand-icon {
    margin: 5px;
    color: #a3a2b3; }
  .full-container .table-header {
    font-weight: bold;
    color: black; }
  .full-container .expand-button {
    width: 100px;
    height: 30px;
    padding: 5px;
    font-size: 15px;
    float: right;
    text-align: center;
    border-radius: 20px;
    background-color: #a3a2b3;
    color: whitesmoke; }
  .full-container .table {
    border-collapse: collapse;
    overflow-y: scroll;
    min-width: 45vh; }
  .full-container .ui.table {
    border: 0px !important; }
  .full-container .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2; }
  .full-container .ui.table thead th {
    cursor: default;
    border-bottom: 0px !important; }
  .full-container .ui.table tr td {
    border-top: 0px !important; }
  .full-container .div-center {
    font-size: 18px;
    width: max-content;
    height: 50px;
    margin-left: 325%;
    margin-top: 60px; }
  .full-container .pricing-error-msg {
    color: red;
    position: relative;
    font-size: 11px; }

.full-pricing-container {
  margin: 0 0 0 3%;
  width: 95%; }
  .full-pricing-container .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    border-radius: 10px; }
  .full-pricing-container .ui.fluid.card {
    margin: 20px 10px 0 10px;
    width: 98%; }
  .full-pricing-container table.borderless {
    border: 1px !important; }
    .full-pricing-container table.borderless th {
      background-color: white !important; }
  .full-pricing-container .pricing-header {
    overflow: hidden; }
  .full-pricing-container .pricing-table {
    overflow-y: auto;
    min-height: 75vh;
    max-height: 75vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 20px 2px 2px 2px; }
  .full-pricing-container ::-webkit-scrollbar {
    display: none; }
  .full-pricing-container .ui.card > .content > .header,
  .full-pricing-container .ui.cards > .card > .content > .header {
    margin-left: 10px;
    padding: 0; }
  .full-pricing-container .ui.table thead th {
    font-size: 16px;
    padding: 0.928571em 1.785714em; }
  .full-pricing-container .ui.dropdown {
    width: 50%; }
  .full-pricing-container .ui.dropdown .menu {
    min-width: 100%; }
  .full-pricing-container .ui.dropdown .menu > .item > .image,
  .full-pricing-container .ui.dropdown .menu > .item > img,
  .full-pricing-container .ui.dropdown > .text > .image,
  .full-pricing-container .ui.dropdown > .text > img {
    vertical-align: middle; }
  .full-pricing-container .labor-rate {
    display: inline-flex;
    color: #343434;
    display: flex;
    float: right;
    font-size: 16px;
    font-weight: 500; }
    .full-pricing-container .labor-rate span {
      width: 100%; }
    .full-pricing-container .labor-rate p {
      margin-left: 20px;
      width: 400px; }
  .full-pricing-container .caradvise-price {
    background-color: #f6f6f6;
    border-radius: 4px;
    margin: 0 30%;
    padding: 0.4rem 1.4rem;
    font-size: 18px;
    font-weight: 600;
    color: #000000 !important; }
  .full-pricing-container .ui.grid {
    margin: 0; }
  .full-pricing-container .caradvise-labor-price {
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 5px 6%;
    font-size: 16px;
    font-weight: 600;
    margin: 0 2%;
    border-radius: 5px;
    text-align: center; }
  .full-pricing-container .caradvise-labor-price-input {
    padding: 5px 2%;
    text-align: center;
    flex: none;
    max-width: 26%;
    margin: 0;
    border-radius: 5px;
    color: #1354fa; }
  .full-pricing-container .retail-savings {
    background-color: #f6f6f6;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    padding: 1% 20%; }
  .full-pricing-container .retail-savings-display-none {
    margin: 0 20%; }
  .full-pricing-container .ui.table td.one.wide,
  .full-pricing-container .ui.table th.one.wide {
    width: 13.25%; }
  .full-pricing-container .ui.table td {
    padding: 0.2em 0.78571429em; }
  .full-pricing-container .labor-rate-price-div {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    width: 7%;
    margin-left: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 600; }
  .full-pricing-container .pricing-row-input {
    text-align: center;
    max-width: 40%;
    margin: 0;
    flex: none; }
  .full-pricing-container .ui.input > input {
    padding: 5px 0;
    text-align: center;
    background-color: #f6f6f6;
    line-height: 0px;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: none;
    font-size: 16px;
    font-weight: 600;
    flex: none;
    position: inherit; }
  .full-pricing-container .ui.input.error > input {
    border: 2px solid #ff6b6b;
    background-color: #f6f6f6; }
  .full-pricing-container .input-pricing {
    font-family: "Montserrat", sans-serif !important;
    background-color: #f6f6f6;
    margin: 0 30%;
    padding: 0.5rem 0.9rem;
    font-weight: 600;
    display: flex;
    width: 135px;
    color: #2f3036;
    background-color: #edf0f5;
    border-radius: 4px; }
    .full-pricing-container .input-pricing .prefix {
      font-size: 9px;
      color: #2f3036;
      font-weight: 600; }
    .full-pricing-container .input-pricing .suffix {
      font-size: 9px;
      color: #67768e;
      cursor: pointer; }
    .full-pricing-container .input-pricing .svg-inline--fa {
      vertical-align: -5px; }
    .full-pricing-container .input-pricing input::-webkit-outer-spin-button,
    .full-pricing-container .input-pricing input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .full-pricing-container .input-pricing input {
      all: initial;
      width: 90px !important;
      font-size: 18px;
      font-weight: 600;
      font-family: "Montserrat", sans-serif !important; }
  .full-pricing-container .input-pricing-error {
    font-family: "Montserrat", sans-serif !important;
    border: 2px solid #ff6b6b;
    margin: 0 30%;
    padding: 0.5rem 0.9rem;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    width: 135px;
    color: #2f3036;
    background-color: #edf0f5;
    border-radius: 4px; }
    .full-pricing-container .input-pricing-error .prefix {
      font-size: 9px;
      color: #2f3036;
      font-weight: 600; }
    .full-pricing-container .input-pricing-error .suffix {
      font-size: 9px;
      color: #67768e;
      cursor: pointer; }
    .full-pricing-container .input-pricing-error .svg-inline--fa {
      vertical-align: -5px; }
    .full-pricing-container .input-pricing-error input::-webkit-outer-spin-button,
    .full-pricing-container .input-pricing-error input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .full-pricing-container .input-pricing-error input {
      all: initial;
      width: 90px !important;
      font-weight: 600;
      font-family: "Montserrat", sans-serif !important; }
  .full-pricing-container .input-rate {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    width: 200px;
    color: #2f3036;
    background-color: #edf0f5;
    border-radius: 4px;
    padding: 1rem 1rem;
    /* Firefox */ }
    .full-pricing-container .input-rate .prefix {
      font-size: 10px;
      color: #2f3036; }
    .full-pricing-container .input-rate .suffix {
      font-size: 10px;
      color: #67768e;
      cursor: pointer; }
    .full-pricing-container .input-rate .svg-inline--fa {
      vertical-align: -5px; }
    .full-pricing-container .input-rate input::-webkit-outer-spin-button,
    .full-pricing-container .input-rate input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .full-pricing-container .input-rate input[type="number"] {
      -moz-appearance: textfield; }
  .full-pricing-container .input-rate-error {
    border: 2px solid #ff6b6b;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    width: 200px;
    color: #2f3036;
    background-color: #edf0f5;
    border-radius: 4px;
    padding: 1rem 1rem;
    /* Firefox */ }
    .full-pricing-container .input-rate-error .prefix {
      font-size: 10px;
      color: #2f3036; }
    .full-pricing-container .input-rate-error .suffix {
      font-size: 10px;
      color: #67768e;
      cursor: pointer; }
    .full-pricing-container .input-rate-error .svg-inline--fa {
      vertical-align: -5px; }
    .full-pricing-container .input-rate-error input::-webkit-outer-spin-button,
    .full-pricing-container .input-rate-error input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .full-pricing-container .input-rate-error input[type="number"] {
      -moz-appearance: textfield; }
  .full-pricing-container input {
    all: unset;
    width: 140px; }
  .full-pricing-container .ui.segment {
    box-shadow: 0px 4px 10px 10px rgba(0, 0, 0, 0.02);
    border-radius: 2px;
    border: none;
    min-width: 445px; }
  .full-pricing-container .save-button {
    z-index: 1;
    position: relative; }
  .full-pricing-container .description {
    display: inline-flex; }
  .full-pricing-container .ui[class*="right floated"].button,
  .full-pricing-container .ui[class*="right floated"].buttons {
    float: right;
    margin-right: 5em;
    margin-left: 0;
    margin-top: -37px;
    margin-bottom: 2em;
    color: #ffffff;
    background: #efefef;
    opacity: 1;
    width: 200px; }
  .full-pricing-container .pricing-labor-rates {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .full-pricing-container .retail-savings-div {
    display: inline-flex;
    text-align: center;
    width: 50%;
    font-size: 16px;
    font-weight: 600; }
  .full-pricing-container .retail-saving-amount-div-margin {
    margin: 0 -10%; }
  .full-pricing-container .retail-savings-margin {
    margin: 0 50%; }
  .full-pricing-container .retail-savings-empty {
    background-color: #f6f6f6;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    padding: 2px 19px;
    margin: 0 50%; }
  .full-pricing-container .info-Icon-retail-price {
    font-size: 10px;
    vertical-align: middle;
    left: 5px; }

.ribbon-container {
  color: white;
  margin: 10% 0 0 11%;
  font-weight: 600;
  font-size: 14px; }

.price-popup {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  color: #1354fa;
  margin: 5px 5px; }

.retail-popup {
  text-align: center;
  font-size: 14px;
  vertical-align: middle; }

.grid-centered {
  position: absolute;
  top: 73%;
  left: 49%;
  transform: translate(-20%, -50%); }

@media screen and (max-width: 1366px) {
  .full-pricing-container .caradvise-labor-price {
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 5px 6%;
    font-size: 16px;
    font-weight: 600;
    margin: 0 1%;
    border-radius: 5px;
    text-align: center; }
  .full-pricing-container .caradvise-price {
    background-color: #f6f6f6;
    border-radius: 4px;
    margin: 0 20%;
    padding: 0.4em 0.9em;
    font-size: 18px;
    font-weight: 600;
    color: #000000 !important; }
  .full-pricing-container .caradvise-labor-price-input {
    padding: 0 1%;
    text-align: center;
    flex: none;
    max-width: 25%;
    margin: 0;
    border-radius: 5px;
    color: #1354fa; }
  .full-pricing-container .retail-savings {
    background-color: #f6f6f6;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    padding: 1% 0; }
  .full-pricing-container .retail-savings-div {
    display: inline-flex;
    text-align: center;
    width: 30%;
    font-size: 16px;
    font-weight: 600; }
  .full-pricing-container .retail-savings-margin {
    margin: 0 20%; }
  .full-pricing-container .pricing-row-input {
    text-align: center;
    max-width: 50%;
    margin: 0 25%;
    flex: none; }
  .full-pricing-container .retail-savings-empty {
    background-color: #f6f6f6;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    padding: 2px 19px;
    margin: 0 20%; }
  .full-pricing-container .notifications-tr {
    margin-right: 3% !important; }
  .full-pricing-container .retail-saving-amount-div-margin {
    margin: 0 0; }
  .full-pricing-container .ui[class*="right floated"].button,
  .full-pricing-container .ui[class*="right floated"].buttons {
    margin-right: 0; } }

main {
  width: 100%;
  height: 100%; }

h2,
small {
  color: grey; }

.wrapper,
.container,
.col-1,
.col-2 {
  padding: 20px;
  display: flex;
  flex: 1 100%; }

.container {
  flex-flow: column nowrap;
  min-height: 100vh;
  width: 100%; }

.col-2,
.col-1 {
  padding: 20px; }

.ui.header {
  margin: 0 80px 0 30px; }

/*  Medium to larger screens. Breakpoint at 980px */
@media (min-width: 61.25em) {
  .container {
    flex-flow: row nowrap; } }

.shop-info-section {
  padding-bottom: 10px;
  padding-top: 10px;
  margin-left: 30px; }

.shop-info-line-item {
  padding-bottom: 16px;
  padding-top: 20px; }
  .shop-info-line-item input {
    padding: 2px;
    height: 32px; }

.united-states-dropdown {
  padding-bottom: 7px;
  padding-top: 7px; }

.editable {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 3px; }

.editable-larger-box {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
  width: 250px;
  padding: 8px;
  border-radius: 3px; }

.unclickable {
  pointer-events: none;
  height: 0;
  width: 0; }

.full-appt-container {
  width: 100%; }
  .full-appt-container .div-table {
    border-collapse: collapse;
    overflow-y: scroll;
    min-height: 25vh;
    max-height: 50vh; }
  .full-appt-container .table-header {
    font-weight: bold;
    color: black; }
  .full-appt-container .ui.table {
    border: 0px !important; }
  .full-appt-container .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2; }
  .full-appt-container .ui.table thead th {
    cursor: default;
    border-bottom: 0px !important; }
  .full-appt-container .ui.table tr td {
    border-top: 0px !important; }
  .full-appt-container .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 60px 10px 0 50px;
    width: 95%;
    border-radius: 15px; }
  .full-appt-container .ui.dropdown {
    border-radius: 30px; }
  .full-appt-container .div-center {
    font-size: 18px;
    width: 200px;
    height: 120px;
    position: absolute;
    top: 10;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }

.div-retail-reasons {
  display: inline-block;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  margin-right: 3px;
  margin-left: 3px;
  margin-bottom: 10px;
  min-width: 60px;
  border: solid grey 1px;
  cursor: pointer;
  width: fit-content; }

.calendar {
  display: block;
  text-align: center; }

@media only screen and (max-width: 567px) {
  .full-order-container {
    width: 50%; }
    .full-order-container .div-table {
      min-width: 50%; } }

@media only screen and (max-width: 1366px) {
  .full-appt-container {
    width: 95%; }
    .full-appt-container .ui.card {
      box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
      margin: 60px 10px 0 50px;
      width: 90%;
      border-radius: 15px; } }

@media screen and (min-width: 1920px) {
  .full-appt-container {
    width: 100%; }
    .full-appt-container .search-box {
      margin-right: 230px; } }

.full-order-container .div-order-table {
  border-collapse: collapse;
  overflow-y: scroll;
  min-height: 30 vh;
  max-height: 50vh; }

.full-order-container .table-header {
  font-weight: bold;
  color: black; }

.full-order-container .ui.table {
  border: 0px !important; }

.full-order-container .ui.table thead tr:first-child > th {
  position: sticky;
  top: 0;
  z-index: 2; }

.full-order-container .ui.table thead th {
  cursor: default;
  border-bottom: 0px !important; }

.full-order-container .ui.table tr td {
  border-top: 0px !important; }

.full-order-container .ui.card {
  box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
  margin: 60px 10px 0 50px;
  width: 95%;
  border-radius: 15px; }

.full-order-container .ui.dropdown {
  border-radius: 30px; }

.full-order-container .div-center {
  font-size: 18px;
  width: max-content;
  height: 100px;
  margin-left: 230%;
  margin-top: 60px; }

.full-order-container .div-center-noData {
  font-size: 18px;
  width: max-content;
  height: 100px;
  margin-left: 290%;
  margin-top: 60px; }

@media only screen and (max-width: 567px) {
  .full-order-container {
    width: 50%; }
    .full-order-container .div-order-table {
      min-width: 50%; } }

@media only screen and (max-width: 1366px) {
  .full-order-container {
    width: 97%; } }

.container {
  width: 100% !important;
  height: auto; }
  .container .ui.fluid.card {
    width: 95%;
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 30px 10px 80px 40px !important;
    border-radius: 15px; }
  .container .shop-info {
    display: flex;
    flex-direction: row;
    margin-top: 20px; }
  .container .my-shop-logo {
    float: left;
    width: 50px;
    height: 46px;
    margin: 20px;
    shape-outside: circle();
    clip-path: circle();
    background: lightblue;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #ffffff;
    background-color: #005ca9; }
  .container .shop-name {
    display: flex;
    flex-direction: row;
    margin-top: 22px;
    font-weight: bold;
    font-size: 17px;
    color: #474747;
    margin-left: -6px; }
  .container .shop-address {
    display: flex;
    font-size: 12px;
    flex-direction: column;
    margin-top: 5px;
    color: #474747;
    margin-left: -5px; }
  .container .display-edit-button {
    display: inline-flex;
    flex: 1;
    flex-direction: column; }
  .container .ui.button {
    margin: 18px 24px 34px 20px;
    border-radius: 20px;
    padding: 0 10px;
    height: 30px;
    width: 80px; }
  .container .shop-details {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 10px; }
  .container .shop-primary-details {
    display: inline-flex;
    flex: 1;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px; }
  .container .headings {
    font-size: 16px;
    color: #474747;
    margin-left: 30px;
    font-weight: bold; }
  .container .details {
    margin-left: 30px; }
  .container .shop-primary-headings {
    margin-left: 30px;
    font-weight: 500; }
  .container .dash-table {
    border-collapse: collapse;
    overflow-y: scroll;
    min-height: 30vh;
    max-height: 30vh;
    max-width: initial;
    border-radius: 10px;
    margin-left: 30px; }
  .container .table-header {
    font-weight: bold;
    color: black; }
  .container .summary-align {
    display: inline-flex;
    margin-left: 30px; }
  .container .ui.table {
    border: 0px !important; }
  .container .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2; }
  .container .ui.table thead th {
    cursor: default;
    border-bottom: 0px !important; }
  .container .ui.table tr td {
    border-top: 0px !important; }
  .container .view-all-button {
    color: #474747;
    position: absolute;
    right: 20px;
    text-decoration: underline; }
  .container .div-center {
    font-size: 18px;
    font-size: 18px;
    width: 199px;
    height: 100;
    position: inherit;
    margin: auto;
    margin: 60px 50%; }
  .container .center {
    font-size: 18px;
    font-size: 18px;
    width: max-content;
    position: absolute;
    margin: auto;
    margin: 12% 35%; }
  .container .text-center {
    font-size: 18px;
    width: max-content;
    top: auto;
    position: inherit;
    margin: auto;
    margin: 180px 25%; }
  .container .image-center {
    float: left;
    margin-top: 30px;
    width: 100%; }
  .container .map-center {
    float: right;
    position: absolute;
    margin-top: 30px;
    margin-left: 50px;
    width: 50%;
    right: 20px; }
  .container .map-dimensions {
    height: 240px;
    margin-right: 20px;
    margin-left: 15px; }
  .container .ui.image {
    position: absolute;
    display: inline-flex;
    width: 45%; }
  .container .ui[class*="two column"].grid > .column:not(.row),
  .container .ui[class*="two column"].grid > .row > .column {
    width: 38%; }
  .container .not-display-icon {
    display: none; }
  .container .display-icon {
    display: block;
    margin-left: 30px;
    cursor: pointer; }
  .container .display-edit-amenities-icon {
    display: block;
    margin-left: 30px;
    cursor: pointer; }

.alignHours {
  width: 75%;
  margin: 0 auto; }

.ui.checkbox {
  position: sticky;
  left: 28vh; }

.ui.checkbox label {
  display: table-header-group;
  padding-left: -0.14286em;
  font-size: 0.92857143em;
  font-weight: 700;
  color: black; }

.ui.checkbox label:after {
  position: absolute;
  top: 35px;
  left: 26px; }

.ui.checkbox label:before {
  position: absolute;
  top: 34px;
  left: 25px; }

.image-dimensions {
  border-radius: 20px;
  height: 240px;
  background-color: #efefef !important; }

.image-preview {
  border-radius: 20px;
  height: 240px;
  width: 350px; }

.div-align {
  display: inline-flex;
  width: 100%; }

.statusLabel {
  margin-right: -5.5%; }

.amenities-edit .ui.multiple.dropdown .menu {
  position: relative;
  border: none;
  width: 110%; }

@media only screen and (max-width: 1366px) {
  .alignHours {
    width: 75%;
    margin: 0 auto; }
  .statusLabel {
    margin-right: -5.5%; }
  .container {
    width: 100%; }
    .container .ui.fluid.card {
      width: 100%; }
    .container .ui[class*="two column"].grid > .column:not(.row),
    .container .ui[class*="two column"].grid > .row > .column {
      width: 47%; }
    .container .headings {
      font-size: 13px; }
    .container .column {
      font-size: 10px; }
    .container .div-center {
      margin: 60px 39%; }
    .container .map-dimensions {
      height: 200px; }
    .container .image-dimensions {
      height: 200px; } }

@media only screen and (max-width: 860px) {
  .alignHours {
    width: 75%;
    margin: 0 12px; } }

@media only screen and (max-width: 767px) {
  .alignHours {
    width: 85%;
    margin: 0 auto; }
    .alignHours .ui.grid > .column:not(.row),
    .alignHours .ui.grid > .row > .column {
      position: relative;
      display: inline-block;
      padding-left: 0rem;
      padding-right: 25px;
      vertical-align: top; } }

.react-datepicker {
  font-size: 1em; }

.react-datepicker__header {
  padding-top: 0.8em;
  color: #ccc; }

.react-datepicker__month {
  margin: 0.5em 1.1em; }

.react-datepicker__day-name,
.react-datepicker__day {
  width: 2em;
  line-height: 2em;
  margin: 0.266em; }

.react-datepicker__day-name {
  color: blue; }

.react-datepicker__current-month {
  font-size: 1.1em; }

.react-datepicker__navigation {
  top: 1.1em;
  line-height: 1.8em;
  border: 0.55em solid transparent; }

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1.1em; }

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1.1em; }

@media only screen and (max-width: 567px) {
  .react-datepicker {
    font-size: 1em; }
  .react-datepicker__header {
    padding-top: 0.8em; }
  .react-datepicker__month {
    margin: 0.4em 1em; }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em; }
  .react-datepicker__current-month {
    font-size: 1em; }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent; }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em; }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em; } }

@media only screen and (max-width: 320px) {
  .react-datepicker {
    font-size: 0.9em; } }

.full-feedback-container {
  margin-bottom: 30px; }
  .full-feedback-container .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 60px 10px 0 50px;
    width: 95%;
    border-radius: 15px; }
    .full-feedback-container .ui.card .content {
      margin: 0 0 40px 0; }
  .full-feedback-container .table-header {
    font-weight: bold;
    color: black; }
  .full-feedback-container .dash-table {
    border-collapse: collapse;
    overflow-y: scroll;
    min-height: 40vh;
    max-height: 45vh;
    width: 95%;
    margin-left: 50px;
    margin-top: 50px; }
  .full-feedback-container .ui.table {
    border: 0px !important; }
  .full-feedback-container .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2; }
  .full-feedback-container .ui.table thead th {
    cursor: default;
    border-bottom: 0px !important; }
  .full-feedback-container .ui.table tr td {
    border-top: 0px !important; }
  .full-feedback-container .rating-summary {
    margin-left: 50px; }
    .full-feedback-container .rating-summary p {
      color: black;
      font-size: medium;
      font-weight: bold; }
  .full-feedback-container .ui.progress {
    position: relative;
    display: block;
    max-width: 100%;
    border: none;
    margin: 0 0 1em;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0.28571429rem; }
    .full-feedback-container .ui.progress .bar {
      background-color: #ffc373; }
  .full-feedback-container .item-count {
    color: #005ca9;
    font-size: 40px;
    text-align: center; }
  .full-feedback-container .progress-chart {
    width: "80%";
    float: "left"; }
  .full-feedback-container .div-filters,
  .full-feedback-container .div-filters-active {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 1rem;
    margin-right: 30px;
    margin-bottom: 10px;
    min-width: 60px;
    border: solid grey 1px;
    cursor: pointer;
    width: 15%;
    text-align: center; }
  .full-feedback-container .view-all-button {
    color: #474747;
    margin-top: 20px;
    text-decoration: underline;
    cursor: pointer; }
  .full-feedback-container .div-center {
    font-size: 18px;
    width: 200px;
    position: absolute;
    left: 0;
    top: 70%;
    bottom: 0;
    right: 0;
    margin: auto; }
  .full-feedback-container .div-align-block {
    display: block;
    margin-top: 15px; }
  @media only screen and (max-width: 1366px) {
    .full-feedback-container .full-feedback-container {
      width: 80%; } }

.margin-left {
  margin-left: 20px; }

.margin-top {
  margin-top: 15px; }

.div-align-flex {
  display: flex;
  margin-top: 15px; }

.align-center {
  text-align: center; }

.full-shop-order-container {
  padding: 40px 65px;
  /* On mouse-over, add a deeper shadow */ }
  .full-shop-order-container .apexcharts-legend-text {
    margin-left: 0px !important; }
  .full-shop-order-container .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    width: 100%;
    height: 90vh;
    border-radius: 20px; }
  .full-shop-order-container .customer-wrap {
    display: flex;
    margin-top: -8% 0 0 0; }
    .full-shop-order-container .customer-wrap p {
      overflow: hidden;
      width: 75%;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 18px;
      color: black;
      font-weight: bold; }
  .full-shop-order-container .shop-orders-summary .ui.buttons .ui.button:first-child {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important; }
  .full-shop-order-container .email-wrap {
    display: flex; }
    .full-shop-order-container .email-wrap p {
      overflow: hidden;
      width: 60%;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .full-shop-order-container .div-card {
    /* Add shadows to create the "card" effect */
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 15px 15px 15px 15px;
    transition: 0.3s;
    border-radius: 20px;
    background-color: white;
    height: 11vh; }
  .full-shop-order-container .div-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); }
  .full-shop-order-container .div-table {
    overflow-y: scroll;
    min-height: 56vh;
    max-height: 56vh;
    margin-top: 25px; }
    .full-shop-order-container .div-table .no-content {
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      height: 40vh; }
  .full-shop-order-container .view-all-button {
    color: #474747;
    text-decoration: underline;
    cursor: pointer;
    margin-top: -10px; }
  .full-shop-order-container .ui.card > :first-child,
  .full-shop-order-container .ui.cards > .card > :first-child {
    border-radius: 20px !important;
    border-top: none !important; }
  .full-shop-order-container .ui.card > :first-child,
  .full-shop-order-container .ui.cards > .card > :first-child {
    border-radius: 20px;
    border-top: none !important; }
  .full-shop-order-container .ui.items > .item > .content > .header:not(.ui) {
    font-size: 24px; }
  .full-shop-order-container .ui.items {
    margin: -1.5em 0px;
    display: flex; }
  .full-shop-order-container .ui.items > .item > .content > .header {
    display: contents;
    color: rgba(0, 0, 0, 0.85); }
  .full-shop-order-container .svg-inline--fa {
    font-size: 30px;
    height: 20px;
    opacity: 1; }
  .full-shop-order-container .dot {
    float: right;
    position: initial;
    margin: -10px 10px;
    height: 35px;
    width: 35px;
    background-color: #d8d8d8;
    border-radius: 50%;
    padding: 7px 10px;
    opacity: 0.5;
    cursor: pointer; }
  .full-shop-order-container .dot-note {
    float: right;
    position: initial;
    margin: -10px 10px;
    height: 35px;
    width: 35px;
    background-color: #005ca9;
    border-radius: 50%;
    padding: 7px 9px;
    cursor: pointer; }
  .full-shop-order-container .fa-layers-counter,
  .full-shop-order-container .fa-layers-text {
    display: inline-block;
    position: absolute;
    text-align: center;
    font-size: small;
    font-weight: bold;
    margin: 2px 1px 0 0;
    color: black; }
  .full-shop-order-container .personal-details {
    margin-left: 110px;
    margin-top: -50px; }
  .full-shop-order-container .RSPBprogressBar {
    height: 2px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: rgba(211, 211, 211, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0; }
  .full-shop-order-container .div-left {
    float: left;
    margin-top: -50px; }
  .full-shop-order-container .div-column {
    margin-top: -50px; }
  .full-shop-order-container .div-progress {
    margin: -20px 60px 0 35px; }
  .full-shop-order-container .span-text {
    color: black; }
  .full-shop-order-container .span-text-tilt {
    color: black;
    margin-bottom: 0px;
    transform-origin: 0 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(-45deg);
    /* Firefox */
    -moz-transform: rotate(45deg);
    /* IE */
    -ms-transform: rotate(45deg);
    /* Opera */
    -o-transform: rotate(45deg);
    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); }
  .full-shop-order-container .sub_div {
    position: absolute;
    bottom: 0px;
    transform: translate(-1px, 98px);
    font-size: 11px; }
  .full-shop-order-container .ui.button {
    background: #005ca9 0% 0% no-repeat padding-box;
    color: #fff;
    margin: 0 2em 0 1em;
    width: 200px;
    padding: 0;
    border-radius: 5px;
    color: #ffffff;
    height: 50px; }
  .full-shop-order-container .ui.mini.button,
  .full-shop-order-container .ui.mini.buttons .button,
  .full-shop-order-container .ui.mini.buttons .or {
    height: 30px; }
  .full-shop-order-container .action-buttons {
    margin-top: 20px;
    margin-left: 40px;
    display: flex; }
  .full-shop-order-container button {
    font-family: "Montserrat", sans-serif !important;
    font-size: 100%;
    font-weight: bold;
    margin: 0 0 0 0;
    color: #fff;
    background-color: #005ca9;
    border-radius: 5px;
    border: none;
    width: 100px;
    height: 20px;
    cursor: pointer; }
  .full-shop-order-container .div-clock {
    padding: 10px 0 0 20px;
    transform: translate(1%, 0);
    color: black; }
  .full-shop-order-container h3,
  .full-shop-order-container h4 {
    margin: 0;
    font-weight: 700;
    padding: 0; }
  .full-shop-order-container .ui.items > .item > .content > .description {
    margin-top: 0;
    max-width: auto;
    font-size: 1em;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87); }
  .full-shop-order-container .ui.small.image,
  .full-shop-order-container .ui.small.images .image,
  .full-shop-order-container .ui.small.images img,
  .full-shop-order-container .ui.small.images svg {
    width: 100%;
    height: 40px;
    margin-top: -10px; }
  .full-shop-order-container .div-search {
    padding: 10px 0 0 20px;
    width: fit-content;
    margin-top: -35px; }
  .full-shop-order-container .ui.celled.grid > .column:not(.row),
  .full-shop-order-container .ui.celled.grid > .row > .column {
    height: 80px;
    box-shadow: -1px 0 0 0 #d4d4d5; }
  .full-shop-order-container .ui.tiny.image,
  .full-shop-order-container .ui.tiny.images .image,
  .full-shop-order-container .ui.tiny.images img,
  .full-shop-order-container .ui.tiny.images svg {
    margin-top: -20px; }
  .full-shop-order-container .ui.input > input {
    background-color: #f2f4f4;
    border: 0px; }
  .full-shop-order-container .ui.input > input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.87);
    font-style: italic; }
  .full-shop-order-container .ui.input > input::-moz-placeholder {
    color: rgba(0, 0, 0, 0.87);
    font-style: italic; }
  .full-shop-order-container .ui.input > input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.87);
    font-style: italic; }
  .full-shop-order-container .apexcharts-inner {
    position: absolute;
    transform: translate(22px, 0); }
  .full-shop-order-container .ui.buttons .button {
    border-top-left-radius: 1.285714rem;
    border-bottom-left-radius: 1.285714rem;
    border-top-right-radius: 1.285714rem;
    border-bottom-right-radius: 1.285714rem;
    background-color: white;
    color: black; }
  .full-shop-order-container ul li::marker {
    font-size: 20px; }
  .full-shop-order-container .ui.buttons .button:hover {
    background-color: white; }
  .full-shop-order-container .ui.buttons .button:focus {
    background-color: #fdba30 !important;
    color: #005ca9; }
  .full-shop-order-container .div-confirmed {
    background: #1354fa !important;
    color: #1354fa;
    height: 12px;
    width: 12px;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: white;
    border-radius: 50px;
    margin: 9px 3px 0 0; }
  .full-shop-order-container .div-requested {
    background: #ffc373 !important;
    color: #ffc373;
    height: 12px;
    width: 12px;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: white;
    border-radius: 50px;
    margin: 9px 3px 0 0; }
  .full-shop-order-container #totalCount span {
    position: absolute;
    left: 193;
    margin-top: 20;
    color: black;
    font-weight: bold;
    font-size: 40;
    width: 100;
    text-align: "-webkit-center";
    top: -232; }
  .full-shop-order-container .ui.progress:last-child .bar {
    height: 15px;
    border-radius: 50px;
    background: #ffc373; }
  .full-shop-order-container .ui.progress:last-child {
    margin: -15px 0px 0px -2.5em;
    height: 15px;
    border-radius: 50px; }
  .full-shop-order-container .ui.progress:first-child {
    margin: 74px 0px 0px 0.5em;
    height: 15px;
    border-radius: 50px; }
  .full-shop-order-container .ui.progress:first-child .bar {
    height: 15px;
    border-radius: 50px;
    background: #005ca9; }
  .full-shop-order-container .summary-index-start {
    position: absolute;
    bottom: 40px;
    left: 60px;
    font-size: 10px;
    font-weight: bold;
    color: #b1b1b1; }
  .full-shop-order-container .appointments-count {
    position: absolute;
    top: 20px;
    font-size: 10px;
    font-weight: 600; }
  .full-shop-order-container .appointments-count-weekly {
    position: absolute;
    top: 0px;
    font-size: 10px;
    font-weight: 600; }
  .full-shop-order-container .appointments-xaxis {
    position: absolute;
    top: -73px;
    font-size: 7px;
    font-weight: 600; }
  .full-shop-order-container .appointments-count-monthly {
    position: absolute;
    top: 20px;
    font-size: 10px;
    font-weight: 600; }
  .full-shop-order-container .summary-index-end {
    position: absolute;
    top: 34px;
    left: 54px;
    font-size: 10px;
    font-weight: bold;
    color: #b1b1b1; }
  .full-shop-order-container .ui.progress {
    font-size: 1rem;
    transform: rotate(-90deg);
    width: 120px; }
  .full-shop-order-container p {
    width: 180px; }
  .full-shop-order-container .person-details {
    margin-left: 110px;
    margin-top: -50px; }
  .full-shop-order-container .top-right {
    position: absolute;
    right: 40px;
    top: -25px; }

@media only screen and (max-width: 567px) {
  .full-shop-order-container {
    width: 95%; } }

@media only screen and (max-width: 1366px) {
  .full-shop-order-container {
    width: 100%; }
    .full-shop-order-container .ui.card {
      box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
      width: 100%;
      height: 89vh;
      border-radius: 20px; }
    .full-shop-order-container .personal-details {
      margin-left: 90px;
      margin-top: -50px; }
    .full-shop-order-container .ui.small.image {
      width: 80%; }
    .full-shop-order-container .ui.items > .item > .content > .header:not(.ui) {
      font-size: 18px; }
    .full-shop-order-container .ui.items {
      margin: -1.5em -7px; }
    .full-shop-order-container .ui.items > .item {
      font-size: 0.8em; }
    .full-shop-order-container .div-card {
      height: 100px; }
    .full-shop-order-container button {
      margin: 0 0 0 -50px; }
    .full-shop-order-container .ui.button {
      margin: 0px 6px 0 5px;
      font-size: 15px; }
    .full-shop-order-container .div-progress {
      margin: -20px 25px 0 5px; }
    .full-shop-order-container p {
      width: 170px; }
    .full-shop-order-container h6 {
      margin-left: -7px; }
    .full-shop-order-container .email-wrap {
      display: flex; }
      .full-shop-order-container .email-wrap p {
        overflow: hidden;
        width: 50%;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .full-shop-order-container .person-details {
      margin-left: 80px;
      margin-top: -50px; }
    .full-shop-order-container .customer-wrap {
      display: flex; }
      .full-shop-order-container .customer-wrap p {
        overflow: hidden;
        width: 75%;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        color: black;
        font-weight: bold; } }

@media only screen and (min-width: 1367px) and (max-width: 1605px) {
  .full-shop-order-container {
    width: 100%; }
    .full-shop-order-container .ui.card {
      box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
      width: 100%;
      height: 89vh;
      border-radius: 20px; }
    .full-shop-order-container .personal-details {
      margin-left: 90px;
      margin-top: -50px; }
    .full-shop-order-container .ui.small.image {
      width: 80%; }
    .full-shop-order-container .ui.items > .item > .content > .header:not(.ui) {
      font-size: 18px; }
    .full-shop-order-container .ui.items {
      margin: -1.5em -7px; }
    .full-shop-order-container .ui.items > .item {
      font-size: 0.8em; }
    .full-shop-order-container .div-card {
      height: 100px; }
    .full-shop-order-container button {
      margin: 0 0 0 -50px; }
    .full-shop-order-container .ui.button {
      margin: 0px 6px 0 5px;
      font-size: 15px; }
    .full-shop-order-container .div-progress {
      margin: -20px 25px 0 5px; }
    .full-shop-order-container p {
      width: 170px; }
    .full-shop-order-container h6 {
      margin-left: -7px; }
    .full-shop-order-container .email-wrap {
      display: flex; }
      .full-shop-order-container .email-wrap p {
        overflow: hidden;
        width: 50%;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .full-shop-order-container .person-details {
      margin-left: 80px;
      margin-top: -50px; }
    .full-shop-order-container .customer-wrap {
      display: flex; }
      .full-shop-order-container .customer-wrap p {
        overflow: hidden;
        width: 75%;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 18px;
        color: black;
        font-weight: bold; } }

@keyframes moveToRight {
  from {
    left: 0; }
  to {
    left: 100%; } }

.progress-outer {
  display: flex;
  align-items: center;
  transform: rotate(-90deg);
  width: 120px; }

.progress-info {
  width: 30px;
  padding-left: 5px; }

.progress {
  width: 100%;
  background: #eee;
  min-height: 1px;
  position: absolute;
  overflow: hidden;
  border-radius: 50px; }

.progress.progress--small {
  height: 3px; }

.progress.progress--medium {
  height: 15px;
  margin-bottom: 50px;
  border-radius: 52px;
  margin-left: -75px; }

.progress.progress--large {
  height: 15px;
  margin-left: -75px;
  margin-top: 40px; }

.progress-bar {
  background: lime;
  height: 100px;
  left: 10px; }

.full-settings-container {
  width: 95%; }
  .full-settings-container .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 30px 10px 0 20px;
    width: 97%;
    border-radius: 15px;
    height: 80vh; }
  .full-settings-container h3 {
    color: black;
    margin-left: 20px; }
  .full-settings-container .ui.header {
    margin: 0 80px 0 30px;
    font-size: 1.9em; }
  .full-settings-container .icon-style {
    margin-left: 30px; }
  .full-settings-container .lable-span {
    margin-left: 10px;
    color: black; }

@media only screen and (max-width: 567px) {
  .full-settings-container {
    width: 50%; }
    .full-settings-container .div-order-table {
      min-width: 50%; } }

@media only screen and (max-width: 1366px) {
  .full-settings-container {
    width: 100%; } }

ul {
  padding: 0;
  margin: 0;
  list-style-type: none; }

.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 210px; }

.carousel {
  position: relative; }

.logo-image {
  margin-top: 4%;
  max-height: 13vh;
  width: 55vh;
  position: absolute;
  z-index: 9999; }

.carousel__slide {
  margin-right: auto;
  margin-left: auto;
  display: none;
  list-style-type: none;
  text-align: center; }
  .carousel__slide .ui.fluid.image,
  .carousel__slide .ui.fluid.image img,
  .carousel__slide .ui.fluid.image svg,
  .carousel__slide .ui.fluid.images,
  .carousel__slide .ui.fluid.images img,
  .carousel__slide .ui.fluid.images svg {
    display: block;
    width: 100vw;
    height: 100vh;
    opacity: 0.3; }
    @media screen and (max-width: 1366px) {
      .carousel__slide .ui.fluid.image,
      .carousel__slide .ui.fluid.image img,
      .carousel__slide .ui.fluid.image svg,
      .carousel__slide .ui.fluid.images,
      .carousel__slide .ui.fluid.images img,
      .carousel__slide .ui.fluid.images svg {
        height: 107vh; } }
  @media (max-width: 958px) {
    .carousel__slide {
      padding-right: 60px;
      padding-left: 60px; } }
  .carousel__slide--active {
    display: block; }

.carousel-slide__content {
  margin-bottom: 19px;
  font-size: 16px; }
  @media (max-width: 991px) {
    .carousel-slide__content {
      font-size: 18px; } }

.carousel-slide-text {
  top: 65%;
  left: 8%;
  font-size: 23px;
  color: #ffffff;
  position: absolute;
  line-height: 1.5;
  width: 80%;
  text-shadow: 1px 1px 3px #000000; }

.carousel-slide-title {
  top: 60%;
  left: 8%;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fdba30;
  position: absolute;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.988235);
  border-width: 2px !important;
  line-height: 1.2; }

.carousel-slide__author,
.carousel-slide__source {
  font-size: 14px; }
  @media (min-width: 992px) {
    .carousel-slide__author,
    .carousel-slide__source {
      font-size: 16px; } }

.carousel-slide__source {
  font-style: italic;
  color: #474747; }

.carousel__arrow {
  position: absolute;
  top: 50%;
  display: block;
  color: #000000;
  cursor: pointer;
  opacity: 0.75;
  transform: translateY(-50%);
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1); }
  .carousel__arrow:focus {
    outline: 0; }
  .carousel__arrow:hover {
    opacity: 0.5; }
  .carousel__arrow--left {
    left: 32px; }
  .carousel__arrow--right {
    right: 32px; }

.carousel__indicators {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 80%;
  left: 8%; }
  .carousel__indicators li:nth-of-type(n + 2) {
    margin-left: 15px; }
  @media screen and (max-width: 1366px) {
    .carousel__indicators {
      top: 83%; } }
  @media screen and (max-width: 1280px) {
    .carousel__indicators {
      top: 85%; } }
  @media screen and (max-width: 992px) {
    .carousel__indicators {
      top: 87%; } }

.carousel__indicator {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 2px solid #fdba30;
  background-color: none;
  cursor: pointer;
  opacity: 5;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1); }
  .carousel__indicator:hover {
    opacity: 0.5; }
  .carousel__indicator--active, .carousel__indicator--active:hover {
    opacity: 0.75;
    background-color: #ffffff; }

.show-note {
  display: inline-flex;
  width: 100%;
  border-radius: 10px;
  margin-top: 5px;
  cursor: pointer; }

.notes-title {
  margin-top: -5px;
  overflow: hidden;
  width: 190px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.notes-summary {
  font-size: 9px;
  color: #dddddd; }

.show-notes-container {
  max-height: 28vh;
  width: 100%;
  overflow-y: scroll; }

.add-note-button {
  color: black;
  display: inline-flex;
  width: auto;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-top: 15px; }
  .add-note-button .add-note-text {
    overflow: hidden;
    width: 175px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 1px; }

.add-note-icon {
  font-size: 15px;
  margin: 5px;
  color: #005ca9; }

.note-comment {
  margin: 10px;
  height: 30vh;
  background-color: #fbfbfb; }

.full-promo-container {
  margin: 20px; }
  .full-promo-container .ui.card {
    width: 350px !important;
    border-radius: 6px !important; }
  .full-promo-container .promotion-card {
    min-height: 330px;
    height: auto;
    padding: 16px 16px 16px 16px;
    border-radius: 14px;
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    background-color: #fff; }
  .full-promo-container .promotion-items {
    display: flex;
    flex-wrap: wrap; }
  .full-promo-container .add-promo-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px; }
  .full-promo-container .add-promo {
    width: 33.33%;
    text-align: center;
    -ms-flex-item-align: inherit;
    align-self: inherit;
    padding: 1rem;
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-sizing: inherit; }
  .full-promo-container .promo-details {
    color: #4a4a4a;
    font-weight: 500; }
  .full-promo-container .last-updated-container .last-updated {
    color: #aeaeae;
    font-weight: 500; }

.promotion-documents {
  margin: 10px 10px;
  flex: 1 1 150px; }
  .promotion-documents .promotion-document-name {
    margin-bottom: 10px;
    font-weight: 600;
    line-break: anywhere; }
  .promotion-documents .uploaded-document {
    width: 100%;
    height: 600px; }

.promotion-shop-details {
  margin: 10px 0px;
  flex: 1 1 150px; }
  .promotion-shop-details .promotion-shop-name {
    margin-bottom: 10px;
    font-weight: 600; }

.document-download-icon {
  float: right;
  font-size: 20px; }

.files-detail {
  position: relative;
  bottom: 20px; }

.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .file-atc-box .file-image {
    width: 130px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px; }
  .file-atc-box .file-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px; }
  .file-atc-box .file-detail {
    flex: 1;
    width: 175px; }
  .file-atc-box .file-name {
    position: absolute;
    margin-top: -20px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 210px;
    white-space: nowrap; }
  .file-atc-box .file-detail h6 {
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px; }
  .file-atc-box .file-detail a[target="_blank"]:not(.notarget):after {
    display: none; }
  .file-atc-box .file-detail p {
    font-size: 12px;
    color: #8194aa;
    line-height: initial;
    font-weight: 400;
    margin-bottom: 8px; }
  .file-atc-box .file-actions {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center; }
  .file-atc-box .file-action-btn,
  .file-atc-box a {
    font-size: 12px;
    color: #8194aa;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: none;
    margin-right: 15px;
    padding: 2px 10px;
    cursor: pointer; }
  .file-atc-box .file-action-btn:hover {
    color: #3d546f;
    text-decoration: none;
    background-color: #7ba8dc;
    border-radius: 3px; }
  .file-atc-box a:hover {
    color: #3d546f;
    text-decoration: underline; }
  .file-atc-box .file-atc-box:last-child {
    margin-bottom: 0; }

.promo-modal .ui.image {
  position: absolute;
  display: inline-flex;
  width: 45%; }

.promo-modal .ui.form .field > .selection.dropdown {
  min-height: 37px !important; }

.document-preview {
  width: 400px;
  margin: auto;
  display: block;
  height: 200px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 22px; }
  .document-preview img {
    width: 100%;
    object-fit: cover; }

.download-all-btn {
  font-size: 14px;
  color: #8194aa;
  font-weight: 400;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
  float: right;
  position: relative; }

.download-all-btn:hover {
  color: #3d546f;
  text-decoration: underline; }

.document-uploader-container {
  /* ------------ */ }
  .document-uploader-container .h1,
  .document-uploader-container .h2,
  .document-uploader-container .h3,
  .document-uploader-container .h4,
  .document-uploader-container .h5,
  .document-uploader-container .h6,
  .document-uploader-container h1,
  .document-uploader-container h2,
  .document-uploader-container h3,
  .document-uploader-container h4,
  .document-uploader-container h5,
  .document-uploader-container h6 {
    color: #475f7b;
    margin-block-start: 0; }
  .document-uploader-container a,
  .document-uploader-container a:hover,
  .document-uploader-container a:focus {
    text-decoration: none;
    transition: 0.5s;
    outline: none; }
  .document-uploader-container .card {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 30px;
    background-color: #fff;
    border: none; }
  .document-uploader-container .card-body {
    padding: 0px; }
  .document-uploader-container .form-submit {
    padding: 13px 30px;
    font-size: 15px;
    letter-spacing: 0.3px;
    font-weight: 400; }
  .document-uploader-container .data-box {
    width: 100%;
    flex: 1; }
  .document-uploader-container .modal-data-title {
    margin-bottom: 10px; }
  .document-uploader-container .data-title h6 {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600; }
  .document-uploader-container .file-upload {
    margin-bottom: 20px;
    width: 450px; }
  .document-uploader-container .file-upload-box {
    border: 1px dashed #b6bed1;
    background-color: #f0f2f7;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8194aa;
    font-weight: 400;
    font-size: 15px; }
  .document-uploader-container .file-upload-box .file-upload-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer; }
  .document-uploader-container .file-link {
    color: #475f7b;
    text-decoration: underline;
    margin-left: 3px; }
  .document-uploader-container .file-upload-box .file-link:hover {
    text-decoration: none; }
  .document-uploader-container .file-atc-box {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }
  .document-uploader-container .file-image {
    /* width: 130px;
          min-width: 130px;
          height: 85px;
          min-height: 85px; */
    width: 130px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px; }
  .document-uploader-container .file-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px; }
  .document-uploader-container .file-detail {
    flex: 1;
    width: calc(100% - 210px); }
  .document-uploader-container .file-detail h6 {
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px; }
  .document-uploader-container .file-detail p {
    font-size: 12px;
    color: #8194aa;
    line-height: initial;
    font-weight: 400;
    margin-bottom: 8px; }
  .document-uploader-container .file-actions {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center; }
  .document-uploader-container .file-action-btn {
    font-size: 12px;
    color: #8194aa;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    margin-right: 15px;
    cursor: pointer; }
  .document-uploader-container .file-action-btn:hover {
    color: #3d546f;
    text-decoration: underline; }
  .document-uploader-container .file-atc-box:last-child {
    margin-bottom: 0; }

.privacy-policy-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  order: 1;
  flex: 1 0 100%; }
  .privacy-policy-footer footer {
    display: flex;
    justify-content: space-between;
    bottom: 0;
    background: #f8f8f8;
    width: auto;
    max-width: inherit;
    overflow-x: hidden; }
    .privacy-policy-footer footer #lang {
      width: 130px;
      padding-left: 10px;
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;
      cursor: pointer;
      outline: none !important;
      border-bottom: 2px solid #006f9f;
      height: 48px; }
  .privacy-policy-footer .info .legal {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    font-weight: 500; }
  .privacy-policy-footer .info .legal > a {
    margin: 0 10px;
    text-decoration: none;
    font-weight: 500; }
  .privacy-policy-footer a[target="_blank"]::after {
    content: " "; }
  .privacy-policy-footer .info {
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f8f8f8;
    color: #777777;
    flex-wrap: wrap; }
  @media screen and (max-width: 600px) {
    .privacy-policy-footer .info {
      justify-content: center;
      flex-direction: column; }
    .privacy-policy-footer .info > .copyright {
      margin-top: 10px; } }
  @media screen and (max-width: 380px) {
    .privacy-policy-footer .info .legal {
      justify-content: center; }
    .privacy-policy-footer .info .legal a {
      margin: 0;
      text-align: center; } }

:export {
  very-light-gray: #f7f7f7;
  light-gray: #efefef;
  gray: #dddddd;
  dark-gray: #474747;
  darkest-gray: #414447;
  orange: #f8991d;
  blue: #0390ff;
  navy-blue: #002d5e;
  caradvise-electric-blue: #0390ff;
  light-green: #8dd68a;
  red: #ff0000;
  primary-color: #005ca9;
  primary-color-gradient-start: #002d5e;
  primary-color-gradient-start: #1b5492;
  primary-color-gradient-start: white;
  whiteTextColor: #ffffff;
  overrideLinkColor: #005ca9;
  fontFamily: "Montserrat", sans-serif !important;
  progressBarActive: #1bff1b;
  progressBarInactive: #dddddd;
  apexChartPrimaryColor: #1354fa;
  apexChartSecondaryColor: #ffc373;
  rescheduleColor: #ffc373; }

body {
  font-family: "Montserrat", sans-serif !important; }

.notification,
.notification-visible {
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 2px;
  font-size: 13px;
  margin: 10px 0px 0px;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  opacity: 1;
  transition: all 0.3s ease-in-out 0s;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform, opacity;
  border: 1px solid #5ea400 !important;
  background-color: #f4e9e9;
  color: #412f2f;
  box-shadow: #ec3d01 0px 0px 1px;
  right: 0px;
  height: auto;
  border-radius: 6px; }

.notification-error {
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 2px;
  font-size: 13px;
  margin: 10px 0px 0px;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  opacity: 1;
  transition: all 0.3s ease-in-out 0s;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform, opacity;
  border: 1px solid #ec3d3d !important;
  background-color: #f4e9e9;
  color: #412f2f;
  box-shadow: #ec3d01 0px 0px 1px;
  right: 0px;
  height: auto;
  border-radius: 6px; }

.notification-title {
  font-size: 16px !important; }

.notifications-tr {
  margin-right: 0 !important; }

.notification-dismiss {
  cursor: pointer;
  font-family: "Montserrat", sans-serif !important;
  font-size: 23px !important;
  position: absolute;
  top: 3px;
  right: 7px;
  line-height: 15px;
  background-color: #f4e9e9 !important;
  color: #ec3d3d !important;
  border-radius: none !important;
  width: 20px;
  height: 20px;
  font-weight: 500 !important;
  text-align: center; }

.cursor-pointer {
  cursor: pointer; }

a {
  color: #005ca9;
  text-decoration: underline; }

.sign-up-cursor-pointer {
  cursor: pointer;
  padding: 8px 10px 0px 0px;
  text-decoration: none; }

.forget-password-cursor-pointer {
  cursor: pointer;
  text-decoration: none;
  padding: 25px 10px 0px 0px; }

.sign-up-cursor-pointer:hover,
.forget-password-cursor-pointer:hover {
  text-decoration: none; }

.cursor-not-allowed {
  cursor: not-allowed !important; }

@media screen and (max-width: 958px) {
  .sign-up-cursor-pointer,
  .forget-password-cursor-pointer {
    display: none; } }

.primary-color {
  color: #005ca9 !important; }

.black-color {
  color: #000000; }

.no-shop-image {
  background-image: url("../images/no-shop-selected.png");
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  overflow-x: hidden; }
  .no-shop-image .centered {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 700; }

.no-session-container .ui.button:hover {
  background-color: #fdba30 !important;
  color: #005ca9 !important;
  border: 1px solid #005ca9 !important; }

.no-session-container .ui.negative.button:hover,
.no-session-container .ui.negative.buttons:hover {
  background-color: #fdba30 !important; }

.cursor-pointer:hover {
  color: #fdba30;
  text-decoration: underline; }

.cursor-pointer {
  color: #005ca9; }

.cursor-pointer:hover {
  color: #fdba30; }

a:hover {
  color: #fdba30;
  text-decoration: underline;
  cursor: pointer; }

.carousel-slide-text {
  color: #000000;
  text-shadow: 1px 1px 3px #ffffff;
  top: 67%; }

.ui.circular.button,
.div-filters {
  background-color: #fafafa !important;
  color: #005ca9 !important;
  border: none; }

.ui.circular.button:hover,
.div-filters:hover {
  background-color: #fdba30 !important;
  color: #005ca9 !important;
  border: 1px solid #005ca9 !important; }

.div-filters-active {
  background-color: #005ca9;
  color: #ffffff; }

.view-all-button:hover {
  color: #fdba30; }
