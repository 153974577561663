// semantic-ui overrides
@import "semantic-overrides.scss";

// elements
@import "elements/buttons.scss";

// layouts
@import "layouts/containers.scss";
@import "layouts/gradients.scss";
@import "components/no_sessions/no_session_layout.scss";

// components
@import "components/users/users.scss";
@import "components/analytics/analytics.scss";
@import "components/dashboard/dashboard.scss";
@import "components/membership/membership.scss";
@import "components/navbar/navbar.scss";
@import "components/sidebar/sidebar.scss";
@import "components/pricing/pricing.scss";
@import "components/shop_information/shop_information.scss";
@import "components/appointments/appointments.scss";
@import "components/orders/orders.scss";
@import "components/location/location.scss";
@import "./react_datepicker_custom";
@import "components/customer_feedback/customer_feedback.scss";
@import "components/shopOrders/shopOrders.scss";
@import "components/progressbar/progressbar.scss";
@import "components/settings/settings.scss";
@import "components/shared/carousel.scss";
@import "components/shared/notes.scss";
@import "components/promotions/promotions.scss";
@import "components/shared/document_uploader.scss";
@import "components/shared/privacy_policy_footer.scss";

:export {
  very-light-gray: $very-light-gray;
  light-gray: $light-gray;
  gray: $gray;
  dark-gray: $dark-gray;
  darkest-gray: $darkest-gray;
  orange: $orange;
  blue: $blue;
  navy-blue: $navy-blue;
  caradvise-electric-blue: $caradvise-electric-blue;

  light-green: $light-green;
  red: $red;
  primary-color: $primary-color;
  primary-color-gradient-start: $primary-color-gradient-start;
  primary-color-gradient-start: $primary-color-gradient-end;
  primary-color-gradient-start: $primary-text-color;
  whiteTextColor: $white;
  overrideLinkColor: $override-link-color;
  fontFamily: $font-family;
  progressBarActive: $progress-bar-active;
  progressBarInactive: $progress-bar-inactive;
  apexChartPrimaryColor: $apex-chart-primary-color;
  apexChartSecondaryColor: $apex-chart-secondary-color;
  rescheduleColor: $reschedule-color;
}

body {
  font-family: $font-family;
}

.notification,
.notification-visible {
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 2px;
  font-size: 13px;
  margin: 10px 0px 0px;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  opacity: 1;
  transition: all 0.3s ease-in-out 0s;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform, opacity;
  border: 1px solid rgb(94, 164, 0) !important;
  background-color: rgb(244, 233, 233);
  color: rgb(65, 47, 47);
  box-shadow: rgb(236, 61, 61 / 90%) 0px 0px 1px;
  right: 0px;
  height: auto;
  border-radius: 6px;
}

.notification-error {
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 2px;
  font-size: 13px;
  margin: 10px 0px 0px;
  padding: 10px;
  display: block;
  box-sizing: border-box;
  opacity: 1;
  transition: all 0.3s ease-in-out 0s;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform, opacity;
  border: 1px solid rgb(236, 61, 61) !important;
  background-color: rgb(244, 233, 233);
  color: rgb(65, 47, 47);
  box-shadow: rgb(236, 61, 61 / 90%) 0px 0px 1px;
  right: 0px;
  height: auto;
  border-radius: 6px;
}

.notification-title {
  font-size: 16px !important;
}
.notifications-tr {
  margin-right: 0 !important;
}

.notification-dismiss {
  cursor: pointer;
  font-family: "Montserrat", sans-serif !important;
  font-size: 23px !important;
  position: absolute;
  top: 3px;
  right: 7px;
  line-height: 15px;
  background-color: rgb(244, 233, 233) !important;
  color: #ec3d3d !important;
  border-radius: none !important;
  width: 20px;
  height: 20px;
  font-weight: 500 !important;
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

a {
  color: $primary-link-color;
  text-decoration: underline;
}

.sign-up-cursor-pointer {
  cursor: pointer;
  padding: 8px 10px 0px 0px;
  text-decoration: none;
}

.forget-password-cursor-pointer {
  cursor: pointer;
  text-decoration: none;
  padding: 25px 10px 0px 0px;
}

.sign-up-cursor-pointer:hover,
.forget-password-cursor-pointer:hover {
  text-decoration: none;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

@media screen and (max-width: 958px) {
  .sign-up-cursor-pointer,
  .forget-password-cursor-pointer {
    display: none;
  }
}

.primary-color {
  color: $primary-color !important;
}

.black-color {
  color: $black;
}

.no-shop-image {
  background-image: url("../images/no-shop-selected.png");
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  overflow-x: hidden;

  .centered {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 700;
  }
}
