.full-promo-container {
  margin: 20px;
  .ui.card {
    width: 350px !important;
    border-radius: 6px !important;
  }

  .promotion-card {
    min-height: 330px;
    height: auto;
    padding: 16px 16px 16px 16px;
    border-radius: 14px;
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    background-color: #fff;
  }

  .promotion-items {
    display: flex;
    flex-wrap: wrap;
  }

  .add-promo-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
  }

  .add-promo {
    width: 33.33%;
    text-align: center;
    -ms-flex-item-align: inherit;
    align-self: inherit;
    padding: 1rem;
    position: relative;
    display: inline-block;
    vertical-align: top;
    box-sizing: inherit;
  }

  .promo-details {
    color: #4a4a4a;
    font-weight: 500;
  }
  .last-updated-container {
    .last-updated {
      color: #aeaeae;
      font-weight: 500;
    }
  }
}
.promotion-documents {
  margin: 10px 10px;
  flex: 1 1 150px;
  .promotion-document-name {
    margin-bottom: 10px;
    font-weight: 600;
    line-break: anywhere;
  }

  .uploaded-document {
    width: 100%;
    height: 600px;
  }
}
.promotion-shop-details {
  margin: 10px 0px;
  flex: 1 1 150px;
  .promotion-shop-name {
    margin-bottom: 10px;
    font-weight: 600;
  }
}

.document-download-icon {
  float: right;
  font-size: 20px;
}
.files-detail {
  position: relative;
  bottom: 20px;
}

.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .file-image {
    width: 130px;
    height: 85px;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 3px;
  }
  .file-image img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
  }
  .file-detail {
    flex: 1;
    width: 175px;
  }

  .file-name {
    position: absolute;
    margin-top: -20px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 210px;
    white-space: nowrap;
  }

  .file-detail h6 {
    word-break: break-all;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .file-detail a[target="_blank"]:not(.notarget):after {
    display: none;
  }

  .file-detail p {
    font-size: 12px;
    color: #8194aa;
    line-height: initial;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .file-actions {
    // display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
  }
  .file-action-btn,
  a {
    font-size: 12px;
    color: #8194aa;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    text-decoration: none;
    margin-right: 15px;
    padding: 2px 10px;
    cursor: pointer;
  }
  .file-action-btn:hover {
    color: #3d546f;
    text-decoration: none;
    background-color: #7ba8dc;
    border-radius: 3px;
  }
  a:hover {
    color: #3d546f;
    text-decoration: underline;
  }
  .file-atc-box:last-child {
    margin-bottom: 0;
  }
}

.promo-modal {
  .ui.image {
    position: absolute;
    display: inline-flex;
    width: 45%;
  }
  .ui.form .field > .selection.dropdown {
    min-height: 37px !important;
  }
}

//Add Rpomotion Modal Styles
.document-preview {
  width: 400px;
  margin: auto;
  display: block;
  height: 200px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 22px;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.download-all-btn {
  font-size: 14px;
  color: #8194aa;
  font-weight: 400;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
  float: right;
  position: relative;
}

.download-all-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}
