.container {
  width: 100% !important;
  height: auto;

  .ui.fluid.card {
    width: 95%;
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 30px 10px 80px 40px !important;
    border-radius: 15px;
  }

  .shop-info {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
  }
  .my-shop-logo {
    float: left;
    width: 50px;
    height: 46px;
    margin: 20px;
    shape-outside: circle();
    clip-path: circle();
    background: lightblue;
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #ffffff;
    background-color: $primary-color;
  }
  .shop-name {
    display: flex;
    flex-direction: row;
    margin-top: 22px;
    font-weight: bold;
    font-size: 17px;
    color: #474747;
    margin-left: -6px;
  }
  .shop-address {
    display: flex;
    font-size: 12px;
    flex-direction: column;
    margin-top: 5px;
    color: #474747;
    margin-left: -5px;
  }

  .display-edit-button {
    display: inline-flex;
    flex: 1;
    flex-direction: column;
  }
  .ui.button {
    margin: 18px 24px 34px 20px;
    border-radius: 20px;
    padding: 0 10px;
    height: 30px;
    width: 80px;
  }

  .shop-details {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 10px;
  }
  .shop-primary-details {
    display: inline-flex;
    flex: 1;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .headings {
    font-size: 16px;
    color: #474747;
    margin-left: 30px;
    font-weight: bold;
  }

  .details {
    margin-left: 30px;
  }

  .shop-primary-headings {
    margin-left: 30px;
    font-weight: 500;
  }
  .dash-table {
    border-collapse: collapse;
    overflow-y: scroll;
    min-height: 30vh;
    max-height: 30vh;
    max-width: initial;
    border-radius: 10px;
    margin-left: 30px;
  }

  .table-header {
    font-weight: bold;
    color: black;
  }

  .summary-align {
    display: inline-flex;
    margin-left: 30px;
  }

  .ui.table {
    border: 0px !important;
  }

  .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .ui.table thead th {
    cursor: default;
    border-bottom: 0px !important;
  }
  .ui.table tr td {
    border-top: 0px !important;
  }

  .view-all-button {
    color: $dark-gray;
    position: absolute;
    right: 20px;
    text-decoration: underline;
  }

  .div-center {
    font-size: 18px;
    font-size: 18px;
    width: 199px;
    height: 100;
    position: inherit;
    margin: auto;
    margin: 60px 50%;
  }

  .center {
    font-size: 18px;
    font-size: 18px;
    width: max-content;
    position: absolute;
    margin: auto;
    margin: 12% 35%;
  }

  .text-center {
    font-size: 18px;
    width: max-content;
    top: auto;
    position: inherit;
    margin: auto;
    margin: 180px 25%;
  }

  .image-center {
    float: left;
    margin-top: 30px;
    width: 100%;
  }

  .map-center {
    float: right;
    position: absolute;
    margin-top: 30px;
    margin-left: 50px;
    width: 50%;
    right: 20px;
  }
  .map-dimensions {
    height: 240px;
    margin-right: 20px;
    margin-left: 15px;
  }
  .ui.image {
    position: absolute;
    display: inline-flex;
    width: 45%;
  }

  .ui[class*="two column"].grid > .column:not(.row),
  .ui[class*="two column"].grid > .row > .column {
    width: 38%;
  }

  .not-display-icon {
    display: none;
  }

  .display-icon {
    display: block;
    margin-left: 30px;
    cursor: pointer;
  }

  .display-edit-amenities-icon {
    display: block;
    margin-left: 30px;
    cursor: pointer;
  }
}

.alignHours {
  width: 75%;
  margin: 0 auto;
}

.ui.checkbox {
  position: sticky;
  left: 28vh;
}
.ui.checkbox label {
  display: table-header-group;
  padding-left: -0.14286em;
  font-size: 0.92857143em;
  font-weight: 700;
  color: black;
}
.ui.checkbox label:after {
  position: absolute;
  top: 35px;
  left: 26px;
}
.ui.checkbox label:before {
  position: absolute;
  top: 34px;
  left: 25px;
}
.image-dimensions {
  border-radius: 20px;
  height: 240px;
  background-color: $no-session-block-color !important;
}

.image-preview {
  border-radius: 20px;
  height: 240px;
  width: 350px;
}

.div-align {
  display: inline-flex;
  width: 100%;
}

.statusLabel {
  margin-right: -5.5%;
}

.amenities-edit {
  .ui.multiple.dropdown .menu {
    position: relative;
    border: none;
    width: 110%;
  }
}

// @media only screen and (max-height: 679px){
//     .alignHours{
//         width: 100%;
//         margin: 0 auto;
//     }
// }

@media only screen and (max-width: 1366px) {
  .alignHours {
    width: 75%;
    margin: 0 auto;
  }

  .statusLabel {
    margin-right: -5.5%;
  }

  .container {
    width: 100%;
    .ui.fluid.card {
      width: 100%;
    }
    .ui[class*="two column"].grid > .column:not(.row),
    .ui[class*="two column"].grid > .row > .column {
      width: 47%;
    }
    .headings {
      font-size: 13px;
    }
    .column {
      font-size: 10px;
    }
    .div-center {
      margin: 60px 39%;
    }
    .map-dimensions {
      height: 200px;
    }
    .image-dimensions {
      height: 200px;
    }
  }
}

@media only screen and (max-width: 860px) {
  .alignHours {
    width: 75%;
    margin: 0 12px;
  }
}

@media only screen and (max-width: 767px) {
  .alignHours {
    width: 85%;
    margin: 0 auto;
    .ui.grid > .column:not(.row),
    .ui.grid > .row > .column {
      position: relative;
      display: inline-block;
      padding-left: 0rem;
      padding-right: 25px;
      vertical-align: top;
    }
  }
}
