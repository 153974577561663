.no-session-container {
  display: flex;
  flex: 1;
  min-height: 100vh;
  overflow: hidden;

  .no-session-cursor-pointer {
    display: none;
  }

  .no-session-logo-region-small {
    display: none;
  }

  .no-session-logo-region {
    background-color: $no-session-block-color;
    width: 50%;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;

    .no-session-logo {
      // margin-top: 35%;
      // max-height: 20vh;
      // width: 55vh;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
  .no-session-container-header {
    display: none;
  }

  .no-session-carousel {
    background-color: $no-session-block-color;
    width: 50%;
    display: flex;
  }

  .wrapper-1 {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .wrapper-2 {
    padding: 30px;
    text-align: center;
  }

  .thank-you-title {
    font-size: 4em;
    font-weight: 900;
    letter-spacing: 3px;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif !important;
  }

  .wrapper-2 p {
    margin: 40px 0 0 0;
    font-weight: 600;
    font-size: 1.3em;
    letter-spacing: 1px;
    line-height: 1.5;
  }
  .welcome-sign-out {
    border: none;
    margin: 30px 0;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif !important;
  }
  @media (min-width: 360px) {
    .thank-you-title {
      font-size: 4.5em;
    }
    .go-home {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 600px) {
    .thankyoucontent {
      max-width: 1000px;
      margin: 0 auto;
    }
    .wrapper-1 {
      height: initial;
      max-width: 620px;
      margin: 0 auto;
      margin-top: 50px;
    }
  }

  .no-session-content-container {
    width: 50%;
    min-height: 100vh;
    background-color: $white;
    flex: 1;
    display: flex;

    .no-session-content {
      padding: 2vh 10vw;
      width: 100%;

      .no-session-content-header {
        display: inline-flex;
        min-height: 8vh;
        flex-direction: row;
        justify-content: flex-end;
        font-weight: 800;
        color: $black;
        position: relative !important;
        align-items: center;
      }

      .no-session-form {
        padding-top: 5vh;
      }

      .no-session-content-footer {
        text-align: center;
        padding-top: 7vh;

        .no-session-content-footer-highlight {
          padding: 20px;
          border: 1px solid $gray;
          border-radius: 5px;
          background-color: $light-gray;
        }
      }
    }
    label.label {
      color: $override-link-color;
    }
  }
}

@media screen and (max-width: 958px) {
  .no-session-container {
    display: block;
    overflow: hidden;

    .no-session-cursor-pointer {
      cursor: pointer;
      display: block;
      position: absolute;
      padding: 20px 10px;
      z-index: 999;
    }

    .no-session-logo-region {
      display: none;
    }

    .no-session-logo-region-small {
      display: flex;
      background-color: $no-session-block-color;
      width: 100%;
      justify-content: center;
      align-items: center;
      max-height: 12vh;

      .no-session-logo-small {
        margin: 8px 0 0 0;
        min-height: 12vh;
        // width: 50%;
        text-align: center;
      }
    }
    .no-session-container-header {
      display: block;
    }
    .no-session-carousel {
      display: none;
    }

    .no-session-content-container {
      width: 100%;
      min-height: 88vh;
      background-color: $white;

      .no-session-content {
        padding: 1.5vh 10vw;

        .no-session-content-header {
          display: inline-block;
          min-height: 5vh;
          justify-content: center;
          align-content: center;
          position: relative;
        }
        .no-session-form {
          padding-top: 2vh;
          .password-spacing {
            .ui.form .field {
              clear: both;
              margin: 0 0 2em !important;
            }
          }
        }
      }
    }
  }
}
