main {
  width: 100%;
  height: 100%;
}

h2,
small {
  color: grey;
}

.wrapper,
.container,
.col-1,
.col-2 {
  padding: 20px;
  display: flex;
  flex: 1 100%;
}

.container {
  flex-flow: column nowrap;
  min-height: 100vh;
  width: 100%;
}

.col-2,
.col-1 {
  padding: 20px;
}

.ui.header {
  margin: 0 80px 0 30px;
}

/*  Medium to larger screens. Breakpoint at 980px */
@media (min-width: 61.25em) {
  .container {
    flex-flow: row nowrap;
  }
}

.shop-info-section {
  padding-bottom: 10px;
  padding-top: 10px;
  margin-left: 30px;
}

.shop-info-line-item {
  padding-bottom: 16px;
  padding-top: 20px;
  input {
    padding: 2px;
    height: 32px;
  }
}

.united-states-dropdown {
  padding-bottom: 7px;
  padding-top: 7px;
}

.editable {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 3px;
}

.editable-larger-box {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.3);
  width: 250px;
  padding: 8px;
  border-radius: 3px;
}

.unclickable {
  pointer-events: none;
  height: 0;
  width: 0;
}
