.react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
    color: #ccc;
  }
  .react-datepicker__month {
    margin: 0.5em 1.1em;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 2em;
    line-height: 2em;
    margin: 0.266em;
  }
  .react-datepicker__day-name {
    color: blue;
  }
  .react-datepicker__current-month {
    font-size: 1.1em;
  }
  .react-datepicker__navigation {
    top: 1.1em;
    line-height: 1.8em;
    border: 0.55em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1.1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1.1em;
  }
  
  @media only screen and (max-width: 567px) {
    .react-datepicker {
      font-size: 1em;
    }
    .react-datepicker__header {
      padding-top: 0.8em;
    }
    .react-datepicker__month {
      margin: 0.4em 1em;
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 1.9em;
      line-height: 1.9em;
      margin: 0.166em;
    }
    .react-datepicker__current-month {
      font-size: 1em;
    }
    .react-datepicker__navigation {
      top: 1em;
      line-height: 1.7em;
      border: 0.45em solid transparent;
    }
    .react-datepicker__navigation--previous {
      border-right-color: #ccc;
      left: 1em;
    }
    .react-datepicker__navigation--next {
      border-left-color: #ccc;
      right: 1em;
    }
  }
  
  @media only screen and (max-width: 320px) {
    .react-datepicker {
      font-size: 0.9em;
    }
  }
  