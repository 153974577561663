.full-shop-order-container {
  padding: 40px 65px;

  .apexcharts-legend-text {
    margin-left: 0px !important;
  }

  .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    width: 100%;
    height: 90vh;
    border-radius: 20px;
  }

  .customer-wrap {
    display: flex;
    margin-top: -8% 0 0 0;
    p {
      overflow: hidden;
      width: 75%;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 18px;
      color: black;
      font-weight: bold;
    }
  }

  .shop-orders-summary {
    .ui.buttons .ui.button:first-child {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }
  }

  .email-wrap {
    display: flex;
    p {
      overflow: hidden;
      width: 60%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .div-card {
    /* Add shadows to create the "card" effect */
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 15px 15px 15px 15px;
    transition: 0.3s;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    height: 11vh;
  }

  /* On mouse-over, add a deeper shadow */
  .div-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .div-table {
    overflow-y: scroll;
    min-height: 56vh;
    max-height: 56vh;
    // transform: translate(0, 8%);
    margin-top: 25px;
    .no-content {
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      height: 40vh;
    }
  }

  .view-all-button {
    color: $dark-gray;
    text-decoration: underline;
    cursor: pointer;
    margin-top: -10px;
  }

  .ui.card > :first-child,
  .ui.cards > .card > :first-child {
    border-radius: 20px !important;
    border-top: none !important;
  }
  .ui.card > :first-child,
  .ui.cards > .card > :first-child {
    border-radius: 20px;
    border-top: none !important;
  }

  .ui.items > .item > .content > .header:not(.ui) {
    font-size: 24px;
  }

  .ui.items {
    margin: -1.5em 0px;
    display: flex;
  }

  .ui.items > .item > .content > .header {
    display: contents;
    color: rgba(0, 0, 0, 0.85);
  }

  .svg-inline--fa {
    font-size: 30px;
    height: 20px;
    opacity: 1;
  }
  .dot {
    float: right;
    position: initial;
    margin: -10px 10px;
    height: 35px;
    width: 35px;
    background-color: rgb(216, 216, 216);
    border-radius: 50%;
    padding: 7px 10px;
    opacity: 0.5;
    cursor: pointer;
  }
  .dot-note {
    float: right;
    position: initial;
    margin: -10px 10px;
    height: 35px;
    width: 35px;
    background-color: $override-link-color;
    border-radius: 50%;
    padding: 7px 9px;
    cursor: pointer;
  }

  .fa-layers-counter,
  .fa-layers-text {
    display: inline-block;
    position: absolute;
    text-align: center;
    font-size: small;
    font-weight: bold;
    margin: 2px 1px 0 0;
    color: black;
  }

  .personal-details {
    margin-left: 110px;
    margin-top: -50px;
  }

  .RSPBprogressBar {
    height: 2px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: rgba(211, 211, 211, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
  }

  .div-left {
    float: left;
    margin-top: -50px;
  }

  .div-column {
    margin-top: -50px;
  }
  .div-progress {
    margin: -20px 60px 0 35px;
  }
  .span-text {
    color: black;
  }

  .span-text-tilt {
    color: black;
    margin-bottom: 0px;
    transform-origin: 0 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(-45deg);

    /* Firefox */
    -moz-transform: rotate(45deg);

    /* IE */
    -ms-transform: rotate(45deg);

    /* Opera */
    -o-transform: rotate(45deg);

    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }

  .sub_div {
    position: absolute;
    bottom: 0px;
    transform: translate(-1px, 98px);
    font-size: 11px;
  }

  .ui.button {
    background: $override-link-color 0% 0% no-repeat padding-box;
    color: #fff;
    margin: 0 2em 0 1em;
    width: 200px;
    padding: 0;
    border-radius: 5px;
    color: #ffffff;
    height: 50px;
  }

  .ui.mini.button,
  .ui.mini.buttons .button,
  .ui.mini.buttons .or {
    height: 30px;
  }

  .action-buttons {
    margin-top: 20px;
    margin-left: 40px;
    display: flex;
  }

  button {
    font-family: "Montserrat", sans-serif !important;
    font-size: 100%;
    font-weight: bold;
    margin: 0 0 0 0;
    color: #fff;
    background-color: $override-link-color;
    border-radius: 5px;
    border: none;
    width: 100px;
    height: 20px;
    cursor: pointer;
  }

  .div-clock {
    padding: 10px 0 0 20px;
    transform: translate(1%, 0);
    color: black;
  }

  h3,
  h4 {
    margin: 0;
    font-weight: 700;
    padding: 0;
  }

  .ui.items > .item > .content > .description {
    margin-top: 0;
    max-width: auto;
    font-size: 1em;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
  }

  .ui.small.image,
  .ui.small.images .image,
  .ui.small.images img,
  .ui.small.images svg {
    width: 100%;
    height: 40px;
    margin-top: -10px;
  }

  .div-search {
    padding: 10px 0 0 20px;
    width: fit-content;
    margin-top: -35px;
  }

  .ui.celled.grid > .column:not(.row),
  .ui.celled.grid > .row > .column {
    height: 80px;
    box-shadow: -1px 0 0 0 #d4d4d5;
  }

  .ui.tiny.image,
  .ui.tiny.images .image,
  .ui.tiny.images img,
  .ui.tiny.images svg {
    margin-top: -20px;
  }

  .ui.input > input {
    background-color: #f2f4f4;
    border: 0px;
  }

  .ui.input > input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.87);
    font-style: italic;
  }
  .ui.input > input::-moz-placeholder {
    color: rgba(0, 0, 0, 0.87);
    font-style: italic;
  }
  .ui.input > input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.87);
    font-style: italic;
  }

  .apexcharts-inner {
    position: absolute;
    transform: translate(22px, 0);
  }
  .ui.buttons .button {
    border-top-left-radius: 1.285714rem;
    border-bottom-left-radius: 1.285714rem;
    border-top-right-radius: 1.285714rem;
    border-bottom-right-radius: 1.285714rem;
    background-color: white;
    color: black;
  }
  ul li::marker {
    font-size: 20px;
  }
  .ui.buttons .button:hover {
    background-color: white;
  }
  .ui.buttons .button:focus {
    background-color: $button-hover-color !important;
    color: $primary-color;
  }
  .div-confirmed {
    background: rgb(19, 84, 250) !important;
    color: rgb(19, 84, 250);
    height: 12px;
    width: 12px;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 50px;
    margin: 9px 3px 0 0;
  }

  .div-requested {
    background: rgb(255, 195, 115) !important;
    color: rgb(255, 195, 115);
    height: 12px;
    width: 12px;
    left: 0px;
    top: 0px;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    border-radius: 50px;
    margin: 9px 3px 0 0;
  }

  #totalCount span {
    position: absolute;
    left: 193;
    margin-top: 20;
    color: black;
    font-weight: bold;
    font-size: 40;
    width: 100;
    text-align: "-webkit-center";
    top: -232;
  }

  .ui.progress:last-child .bar {
    height: 15px;
    border-radius: 50px;
    background: #ffc373;
  }
  .ui.progress:last-child {
    margin: -15px 0px 0px -2.5em;
    height: 15px;
    border-radius: 50px;
  }
  .ui.progress:first-child {
    margin: 74px 0px 0px 0.5em;
    height: 15px;
    border-radius: 50px;
  }
  .ui.progress:first-child .bar {
    height: 15px;
    border-radius: 50px;
    background: $override-link-color;
  }

  .summary-index-start {
    position: absolute;
    bottom: 40px;
    left: 60px;
    font-size: 10px;
    font-weight: bold;
    color: #b1b1b1;
  }

  .appointments-count {
    position: absolute;
    top: 20px;
    font-size: 10px;
    font-weight: 600;
  }

  .appointments-count-weekly {
    position: absolute;
    top: 0px;
    font-size: 10px;
    font-weight: 600;
  }

  .appointments-xaxis {
    position: absolute;
    top: -73px;
    font-size: 7px;
    font-weight: 600;
  }

  .appointments-count-monthly {
    position: absolute;
    top: 20px;
    font-size: 10px;
    font-weight: 600;
  }

  .summary-index-end {
    position: absolute;
    top: 34px;
    left: 54px;
    font-size: 10px;
    font-weight: bold;
    color: #b1b1b1;
  }

  .ui.progress {
    font-size: 1rem;
    transform: rotate(-90deg);
    width: 120px;
  }
  p {
    width: 180px;
  }
  .person-details {
    margin-left: 110px;
    margin-top: -50px;
  }
  .top-right {
    position: absolute;
    right: 40px;
    top: -25px;
  }
}

@media only screen and (max-width: 567px) {
  .full-shop-order-container {
    width: 95%;
  }
}

@media only screen and (max-width: 1366px) {
  .full-shop-order-container {
    width: 100%;

    .ui.card {
      box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
      width: 100%;
      height: 89vh;
      border-radius: 20px;
    }

    .personal-details {
      margin-left: 90px;
      margin-top: -50px;
    }

    .ui.small.image {
      width: 80%;
    }
    .ui.items > .item > .content > .header:not(.ui) {
      font-size: 18px;
    }
    .ui.items {
      margin: -1.5em -7px;
    }
    .ui.items > .item {
      font-size: 0.8em;
    }
    .div-card {
      height: 100px;
    }
    button {
      margin: 0 0 0 -50px;
    }
    .ui.button {
      margin: 0px 6px 0 5px;
      font-size: 15px;
    }
    .div-progress {
      margin: -20px 25px 0 5px;
    }
    p {
      width: 170px;
    }
    h6 {
      margin-left: -7px;
    }
    .email-wrap {
      display: flex;
      p {
        overflow: hidden;
        width: 50%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .person-details {
      margin-left: 80px;
      margin-top: -50px;
    }
    .customer-wrap {
      display: flex;
      p {
        overflow: hidden;
        width: 75%;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        color: black;
        font-weight: bold;
      }
    }
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1605px) {
  .full-shop-order-container {
    width: 100%;

    .ui.card {
      box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
      width: 100%;
      height: 89vh;
      border-radius: 20px;
    }

    .personal-details {
      margin-left: 90px;
      margin-top: -50px;
    }

    .ui.small.image {
      width: 80%;
    }
    .ui.items > .item > .content > .header:not(.ui) {
      font-size: 18px;
    }
    .ui.items {
      margin: -1.5em -7px;
    }
    .ui.items > .item {
      font-size: 0.8em;
    }
    .div-card {
      height: 100px;
    }
    button {
      margin: 0 0 0 -50px;
    }
    .ui.button {
      margin: 0px 6px 0 5px;
      font-size: 15px;
    }
    .div-progress {
      margin: -20px 25px 0 5px;
    }
    p {
      width: 170px;
    }
    h6 {
      margin-left: -7px;
    }
    .email-wrap {
      display: flex;
      p {
        overflow: hidden;
        width: 50%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .person-details {
      margin-left: 80px;
      margin-top: -50px;
    }
    .customer-wrap {
      display: flex;
      p {
        overflow: hidden;
        width: 75%;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 18px;
        color: black;
        font-weight: bold;
      }
    }
  }
}
