.full-appt-container {
  width: 100%;
  .div-table {
    border-collapse: collapse;
    overflow-y: scroll;
    min-height: 25vh;
    max-height: 50vh;
  }

  .table-header {
    font-weight: bold;
    color: black;
  }

  .ui.table {
    border: 0px !important;
  }

  .ui.table thead tr:first-child > th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .ui.table thead th {
    cursor: default;
    border-bottom: 0px !important;
  }
  .ui.table tr td {
    border-top: 0px !important;
  }

  .ui.card {
    box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
    margin: 60px 10px 0 50px;
    width: 95%;
    border-radius: 15px;
  }

  .ui.dropdown {
    border-radius: 30px;
  }

  .div-center {
    font-size: 18px;
    width: 200px;
    height: 120px;
    position: absolute;
    top: 10;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.div-retail-reasons {
  display: inline-block;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  margin-right: 3px;
  margin-left: 3px;
  margin-bottom: 10px;
  min-width: 60px;
  border: solid grey 1px;
  cursor: pointer;
  width: fit-content;
}

.calendar {
  display: block;
  text-align: center;
}

@media only screen and (max-width: 567px) {
  .full-order-container {
    width: 50%;

    .div-table {
      min-width: 50%;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .full-appt-container {
    width: 95%;
    .ui.card {
      box-shadow: 1px 1px 7px 1px rgba(137, 156, 197, 0.39);
      margin: 60px 10px 0 50px;
      width: 90%;
      border-radius: 15px;
    }
  }
}

@media screen and (min-width: 1920px) {
  .full-appt-container {
    width: 100%;
    .search-box {
      margin-right: 230px;
    }
  }
}
